import React, { useState, useCallback, ComponentType, MouseEvent } from 'react';
import Tooltips from './Tooltips';
import TooltipContent from './TooltipContent';

interface TooltipSettings {
  className?: string | { container: string; content: string };
  styles?: { container?: React.CSSProperties; content?: React.CSSProperties };
}

interface TooltipState {
  display: boolean;
  rect: { top: number; left: number; width: number; height: number; bottom: number } | null;
  title: string | null;
}

interface WithTooltipProps {
  settings?: TooltipSettings;
  title?: string;
}

const tooltip = <P extends object>(
  WrappedComponent: ComponentType<P>,
  settings: TooltipSettings = {}
) => {
  return (props: P & WithTooltipProps) => {
    const [tooltipState, setTooltipState] = useState<TooltipState>({
      display: false,
      rect: null,
      title: props.title || null,
    });

    const onMouseEnter = useCallback((e: MouseEvent<HTMLDivElement>) => {
      const target = e.currentTarget;
      setTooltipState({
        display: true,
        rect: target.getBoundingClientRect(),
        title: props.title || null,
      });
    }, [props.title]);

    const onMouseLeave = useCallback(() => {
      setTooltipState({
        display: false,
        rect: null,
        title: null,
      });
    }, []);

    const { title, settings: propSettings, ...rest } = props;

    return (
      <>
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...rest}>
          <WrappedComponent {...(rest as P)} />
        </div>
          <Tooltips>
            <TooltipContent
              title={tooltipState.title}
              rect={tooltipState.rect}
              display={tooltipState.display}
              {...settings}
              {...propSettings}
            />
          </Tooltips>
      </>
    );
  };
};

export default tooltip;
