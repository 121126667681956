import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import NotificationItem from 'components/molecules/NotificationItems';
import useNotification from 'hooks/notification/useNotification';
import useSpaceReceiver from 'hooks/space/useSpaceReceiver';
import useNotificationStore from 'store/notificationStore';
import useAlert from 'store/useAlert';
import useModalStore from 'store/useModalStore';
import {
  convertTimestamp,
  getErrorMessage,
  getSuccessMessage,
} from 'utils/helper';
import styles from './NotificationModal.module.scss';

const NotificationModal = () => {
  const [offset, setOffset] = useState(0);
  const { onNotificationStatusUpdate } = useSpaceReceiver();
  const { closeModal } = useModalStore();
  const { t } = useTranslation();
  const {
    notificationsList,
    getNotificationsList,
    clearNotification,
    updateNotificationStatus,
  } = useNotification(offset);

  const setAlert = useAlert((state) => state.setAlert);
  const isNotification = useNotificationStore((state) => state.isNotification);
  const setNotificationStatus = useNotificationStore(
    (state) => state.setNotificationStatus
  );

  const onUpdateNotificationStatus = () => {
    updateNotificationStatus()
      .then((response) => {
        if (response.success) console.log('status changed');
      })
      .catch((error) => {
        setAlert(t(getErrorMessage(error.subStatusCode)));
      });
  };

  const onUpdateNotificationStatusUpdate = () => {
    if (isNotification) {
      onUpdateNotificationStatus();
      setNotificationStatus(false);
    }
  };

  useEffect(() => {
    getNotificationsList();
    onUpdateNotificationStatusUpdate();
  }, []);

  // const onClearNotifications = () => {
  //   clearNotification()
  //     .then((response) => {
  //       if (response.success) setAlert('Success', 'success');
  //       onUpdateNotificationStatusUpdate();
  //     })
  //     .catch((error) => {
  //       setAlert(t(getErrorMessage(error.subStatusCode)));
  //     });
  // };

  const onChangeReceiverStatus = useCallback(
    (status: 'active' | 'deactivated', spaceNotificationId: string) => {
      onNotificationStatusUpdate({ status, spaceNotificationId })
        .then((response) => {
          if (response?.success) {
            onUpdateNotificationStatusUpdate();
            setAlert(t(getSuccessMessage('default')), 'success');
            closeModal();
            getNotificationsList();
          }
        })
        .catch((error: ResponseError) =>
          setAlert(t(getErrorMessage(error.subStatusCode)))
        );
    },
    [onNotificationStatusUpdate, setAlert, t, closeModal]
  );

  return (
    <div className={styles.notificationModal}>
      <div className={styles.title}>{t('labels.notification')}</div>
      <div className={styles.itemWrapper}>
        {notificationsList?.result.docs.map((notification) => (
          <NotificationItem
            key={notification._id}
            name={notification.receiverName}
            time={convertTimestamp(notification.createdAt)}
            onAccept={() => {
              onChangeReceiverStatus(
                'active',
                notification.spaceNotificationId
              );
            }}
            onReject={() => {
              onChangeReceiverStatus(
                'deactivated',
                notification.spaceNotificationId
              );
            }}
          />
        ))}
        {!notificationsList?.result.docs.length && (
          <p>{t('labels.noNotification')}</p>
        )}
      </div>
      <div className={styles.buttonWrapper}>
        {notificationsList?.result.hasNextPage && (
          <Button
            className="activeUpload"
            onClick={() =>
              setOffset(
                notificationsList?.result.limit * notificationsList?.result.page
              )
            }
          >
            {t('buttons.loadMore')}
          </Button>
        )}
        {/* {notificationsList?.result.docs.length ? (
          <Button className="activeUpload" onClick={onClearNotifications}>
            {t('buttons.clearAll')}
          </Button>
        ) : null} */}
      </div>
    </div>
  );
};

export default NotificationModal;
