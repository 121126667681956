import React, { useEffect } from 'react';
import { Button, Input } from '@willeder/component-library';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/atoms/CheckBox';
import ColorOption from 'components/atoms/ColorOption/ColorOption';
import useModalStore from 'store/useModalStore';
import { convertTimestamp, getErrorMessage, getSuccessMessage } from 'utils/helper';
import { colorOptions, timeOptions } from 'utils/constant';
import useSpace from 'hooks/space/useSpace';
import useAlert from 'store/useAlert';
import styles from './EditSpaceModal.module.scss';
import userSpaceStore from 'store/userSpaceStore';

const EditSpaceModal = () => {
  const { t } = useTranslation();

  const setAlert = useAlert((state) => state.setAlert);
  const { closeModal } = useModalStore();
  const spaceId = userSpaceStore((state) => state.currentSpaceId);
  const spaceSizeData = userSpaceStore((state) => state.spaceSizeData);
  const { spaceData, updateSpace, isLoading } = useSpace(spaceId);

  const validationSchema = yup.object().shape({
    spaceColor: yup.string().oneOf(colorOptions.map((color) => color.id)),
    spaceName: yup
      .string()
      // .required(t(getErrorMessage('3009')))
      // .min(3, t('errors.minCharacters', { min: '3' }))
      .max(20, t('errors.maxCharacters', { max: '20' })),
    deactivateAt: yup.string().required(t(getErrorMessage('3009'))),
    accessPermission: yup.boolean().required(t(getErrorMessage('3009'))),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      spaceColor: spaceData?.data.spaceColor,
      spaceName: spaceData?.data.spaceName,
      deactivateAt: spaceData?.data.deactivateAt,
      accessPermission: spaceData?.data.accessPermission,
    },
  });

  useEffect(() => {
    if (spaceData?.success) {
      const { spaceColor, spaceName, deactivateAt, accessPermission } =
        spaceData.data;
      setValue('spaceColor', spaceColor);
      setValue('spaceName', spaceName);
      setValue('deactivateAt', deactivateAt);
      setValue('accessPermission', accessPermission);
    }
  }, [spaceData]);

  const onEditSpace = (spaceData: any) => {
    updateSpace(spaceData)
      .then((data) => {
        setAlert(t(getSuccessMessage('default')), 'success');
        closeModal();
      })
      .catch((error: ResponseError) => {
        setAlert(t(getErrorMessage(error.subStatusCode)));
      });
  };

  return (
    <div className={styles.editModal}>
      <h1 className={styles.title}>{t('labels.editSpace')}</h1>
      <Input
        label={t('fields.spaceName')}
        placeholder={t('placeholders.enterSpaceName')}
        inputProps={{ ...register('spaceName') }}
        errorMessage={errors.spaceName?.message}
      />
      <div className="willeder-inputWrapper">
        <div className={styles.color}>
          <h2 className={styles.subTitle}>{t('fields.color')}</h2>
          <Controller
            name="spaceColor"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <div className={styles.spaceColors}>
                {colorOptions.map((option) => (
                  <ColorOption
                    key={option.id}
                    {...option}
                    field={{ ...field }}
                  />
                ))}
              </div>
            )}
          />
        </div>
        <p className="willeder-input-error">{errors.spaceColor?.message}</p>
      </div>
      <div className="willeder-inputWrapper">
        <div className={styles.inputRange}>
          <p>{t('fields.onlineAt')}</p>
          {spaceSizeData?.planType === 'free' ? (
            <p>3日(day)</p>
          ) : (
            <select {...register('deactivateAt')}>
              {timeOptions?.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          )}
        </div>
        <p className="willeder-input-error">{errors.deactivateAt?.message}</p>
      </div>
      <div className="willeder-inputWrapper">
        <CheckBox
          label={t('labels.initialAccessPermission')}
          variant="space"
          inputProps={{ ...register('accessPermission') }}
        />
        <p className="willeder-input-error">
          {errors.accessPermission?.message}
        </p>
      </div>
      <div className={styles.createAtWrapper}>
        <p className={styles.text}>{t("labels.spaceCreationDate")} {spaceData?.data?.createdAt && convertTimestamp(spaceData?.data?.createdAt, true)}</p>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          className="update"
          onClick={handleSubmit(onEditSpace)}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {t('buttons.save')}
        </Button>
        <Button className="close" onClick={closeModal} disabled={isLoading}>
          {t('buttons.close')}
        </Button>
      </div>
    </div>
  );
};

export default EditSpaceModal;
