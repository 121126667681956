import useSWRMutation from 'swr/mutation';
import API_ROUTES from 'services/apiRoutes';
import useAuthStore from 'store/authStore';
import { setCookies } from 'utils/cookies';
import { useSecretCode } from '../encryption/useSecretCode';
import useSecretAuth from './useSecretAuth';
import { useAPI } from '../useAPI';
import useGuestToUser from 'hooks/receiver/useGuestToUser';

const useGoogleAuth = () => {
  const { put } = useAPI();
  const { getNewSecretAuthCode } = useSecretCode();
  const { validateRegisterSecretCode } = useSecretAuth();
  const setIsAuthenticated = useAuthStore((state) => state.setIsAuthenticated);
  const setAuthInformation = useAuthStore((state) => state.setAuthInformation);
  const { setNewUser } = useAuthStore();
  const isGuest = localStorage.getItem('isGuest');
  const { onConvertGuestToUser } = useGuestToUser();

  const sendGoogleAuthRequest = async (
    url: string,
    { arg }: { arg: GoogleAuthPayload }
  ) => {
    const data: GoogleOAuthResponse = await put(url, arg);

    if (data.success) {
      const {
        userId,
        secretKey,
        secretSalt,
        isNewUser,
        accessToken,
        refreshToken,
        loginToken,
      } = data.result;

      const authInformation = {
        isNewUser,
        userId,
        secretKey,
        secretSalt,
        loginToken: loginToken || '',
      };
      setAuthInformation(authInformation);

      if (!isNewUser) {
        setCookies('loginToken', loginToken || '');
      } else {
        setNewUser(true);
        setCookies('accessToken', accessToken || '');
        setCookies('refreshToken', refreshToken || '', 30);
        setCookies('authInformation', JSON.stringify(authInformation));

        if (isGuest === 'true') onConvertGuestToUser();

        const { secretAuth, encryptedSecretCode } = getNewSecretAuthCode(
          userId,
          secretKey,
          secretSalt
        );
        validateRegisterSecretCode({
          secretAuth: secretAuth,
          accessToken: accessToken || '',
        }).then((response) => {
          if (response.success) {
            localStorage.setItem('encryptedSecretCode', encryptedSecretCode);
            setIsAuthenticated(true);
          }
        });
      }
    }
    return data;
  };

  const {
    trigger: onLoginWithGoogle,
    isMutating: isLoading,
    error,
  } = useSWRMutation(API_ROUTES.googleOAuth, sendGoogleAuthRequest);

  return {
    onLoginWithGoogle,
    isLoading,
    error,
  };
};

export default useGoogleAuth;
