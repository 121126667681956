import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import useSWRMutation from 'swr/mutation';
import useReceiverSpace from './useReceiverSpace';
import { useCryptoLink } from 'hooks/encryption/useCryptoLink';
import useModalStore from 'store/useModalStore';

const useGuestToUser = () => {
  const receiverId = localStorage.getItem('receiverId');
  const senderKey = localStorage.getItem('senderKey');
  const terminalId = localStorage.getItem('terminalId');

  const { put } = useAPI();
  const { getReceiverSpaceData } = useReceiverSpace();
  const { getEncryptedSenderKeyForGuestToUser } = useCryptoLink();
  const { setOpenModal } = useModalStore((state) => state);

  const sendUpdateRequest = async (
    url: string,
    { arg }: { arg: Partial<CheckSpaceAccessPayload> }
  ) => {
    const response: ReceiverSpaceResponse = await put(url, arg);
    return response;
  };

  const {
    trigger: convertGuestToUser,
    isMutating: isLoading,
    error,
  } = useSWRMutation(
    receiverId ? API_ROUTES.convertGuestToUser(receiverId) : null,
    sendUpdateRequest
  );

  const onConvertGuestToUser = () => {
    if (receiverId && senderKey && terminalId) {
      const encryptedSenderKey = getEncryptedSenderKeyForGuestToUser(senderKey);

      convertGuestToUser({ encryptedSenderKey, terminalId })
        .then((response: { success: any }) => {
          if (response.success) {
            getReceiverSpaceData();
            localStorage.removeItem('receiverId');
            localStorage.removeItem('terminalId');
            localStorage.removeItem('isGuest');
            setOpenModal('guestToUserModal');
          }
        })
        .catch((e: ResponseError) => {
          console.log(e);
        });
    }
  };

  return {
    onConvertGuestToUser,
    convertGuestToUser,
    isLoading,
    error,
  };
};

export default useGuestToUser;
