import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import useSWRMutation from 'swr/mutation';

const useResetPassword = () => {
  const { put } = useAPI();

  const sendRestPasswordRequest = async (
    url: string,
    { arg }: { arg: ResetPasswordPayload }
  ) => {
    const response: { success: boolean } = await put(url, arg);
    return response;
  };

  const { trigger, isMutating, error } = useSWRMutation(
    API_ROUTES.resetPassword,
    sendRestPasswordRequest
  );

  return {
    onResetPassword: trigger,
    isLoading: isMutating,
    error,
  };
};

export default useResetPassword;
