import React from 'react';
import { Button } from '@willeder/component-library';
import Modal from 'components/atoms/Modal';
import { ConfirmModalProps } from 'types/modals';
import styles from './ConfirmModal.module.scss';

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  content,
  cancel,
  confirm,
  isOpen,
  isLoading = false,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <div className={styles.confirmModal}>
        <h1>{title}</h1>
        <div className={styles.description}>{content}</div>
        <div className={`${styles.buttonWrapper} ${styles.columnAlignCenter}`}>
          <Button
            className="noButton full-width"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={confirm.onClick}
          >
            {confirm.children}
          </Button>
          <Button
            className="confirmButton full-width"
            disabled={isLoading}
            onClick={cancel.onClick}
          >
            {cancel.children}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
