import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGoogleLogin } from '@react-oauth/google';
import { Button, Input } from '@willeder/component-library';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLogin from 'hooks/auth/useLogin';
import useGoogleAuth from 'hooks/auth/useGoogleAuth';
import useAlert from 'store/useAlert';
import useAuthStore from 'store/authStore';
import useModalStore from 'store/useModalStore';
import { emailRegex, passwordRegex } from 'utils/constant';
import { getErrorMessage, getSuccessMessage } from 'utils/helper';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google.svg';
import Logo from '../../assets/icons/logo.svg';
import styles from './Login.module.scss';

const Login = () => {
  const navigate = useNavigate();
  const setAlert = useAlert((state) => state.setAlert);
  const { t } = useTranslation();
  const { onLogin, isLoading } = useLogin();
  const { onLoginWithGoogle, isLoading: googleAuthLoading } = useGoogleAuth();
  const locale = useAuthStore((state) => state.locale);
  const { setOpenModal, setModalProps } = useModalStore();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t(getErrorMessage('3004')))
      .required(t(getErrorMessage('3003')))
      .matches(emailRegex, t(getErrorMessage('3004')))
      .max(255, t('errors.maxCharacters', { max: '255' })),
    password: yup
      .string()
      .required(t(getErrorMessage('3001')))
      .matches(passwordRegex, t(getErrorMessage('3002'))),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      onLoginWithGoogle({ token: codeResponse.code, locale })
        .then((data) => {
          if (data.success) {
            const responseData = data.result;
            if (!responseData.isNewUser) navigate('/secret-code');
            else {
              setOpenModal('successModal');
              setModalProps({
                message: t(getSuccessMessage('8003')),
                onContinue: () => navigate('/'),
              });
            }
          }
        })
        .catch((error: ResponseError) => {
          setAlert(t(getErrorMessage(error.subStatusCode)));
        });
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  const handleLogin = (userdata: LoginPayload) => {
    onLogin(userdata)
      .then((data) => {
        if (data.success) navigate('/secret-code');
      })
      .catch((error: ResponseError) => {
        setAlert(t(getErrorMessage(error.subStatusCode)));
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <img src={Logo} alt="Logo" />
        {/* <h1>{t('titles.welcomeBack')}</h1> */}
      </div>
      <Input
        label={t('fields.email')}
        placeholder={t('placeholders.email')}
        inputProps={{
          ...register('email'),
          type: 'email',
        }}
        errorMessage={errors.email?.message}
      />
      <Input
        label={t('fields.password')}
        placeholder={t('placeholders.password')}
        inputProps={{
          ...register('password'),
          type: 'password',
        }}
        errorMessage={errors.password?.message}
      />
      <Button
        onClick={handleSubmit(handleLogin)}
        className="test"
        disabled={isLoading || googleAuthLoading}
        isLoading={isLoading}
      >
        {t('buttons.login')}
      </Button>
      <div className={styles.ordivider}>
        <span className={styles.line} />
        <p>or</p>
        <span className={styles.line} />
      </div>
      <Button
        className="outlined"
        icon={<GoogleIcon />}
        iconPosition="left"
        disabled={isLoading || googleAuthLoading}
        isLoading={googleAuthLoading}
        onClick={() => googleLogin()}
      >
        {t('buttons.googleSignup')}
      </Button>
      <Link to="/forgot-password">
        <h2>{t('common.forgotPassword')}</h2>
      </Link>
      <p className={styles.account}>
        {t('labels.postspaceAccount')}{' '}
        <Link to="/signup" className={styles.register}>
          {t('links.register')}
        </Link>
      </p>
    </div>
  );
};

export default Login;
