import React from 'react';
import { Button } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import useAlert from 'store/useAlert';
import { GenerateCodeModalProps } from 'types/modals';
import './GenerateCodeModal.scss';

const GenerateCodeModal: React.FC<GenerateCodeModalProps> = ({
  secretCode,
  onClose,
}) => {
  const { t } = useTranslation();
  const setAlert = useAlert((state) => state.setAlert);

  return (
    <div className={'generateModal'}>
      <h1 className="title">{t('titles.secretCode')}</h1>
      <h2 className="code">{secretCode}</h2>
      <p className="description">{t('labels.generatedSecretCode')}</p>
      <div className="buttonWrapper">
        <Button
          className="update"
          onClick={() => {
            navigator.clipboard.writeText(secretCode);
            setAlert(t('success.linkCopied'), 'info');
          }}
        >
          {t('buttons.copySecretCode')}
        </Button>
        <Button className="close" onClick={onClose}>
          {t('buttons.close')}
        </Button>
      </div>
    </div>
  );
};

export default GenerateCodeModal;
