const DATA_LIMIT = 20;

const API_ROUTES = {
  // AUTHENTICATION ENDPOINTS
  login: `/user/auth/login`,
  googleOAuth: `/user/auth/google/oauth`,
  logout: `/user/auth/logout`,
  refreshToken: `/user/auth/refresh`,
  verifySecretCode: `/user/auth/secureAuth/validation`,

  registerSendOTP: `/user/auth/register`,
  register: `/user/auth/otp/verify`,
  secureAuth: `/user/auth/secureAuth`,

  forgotPassword: `/user/auth/password/forgot`,
  verifyPasswordOTP: `/user/auth/otp/validation`,
  resetPassword: `/user/auth/password/reset`,
  resetSecureAuth: `/user/auth/secretCode/reset`,

  // SPACE ENDPOINTS
  createSpace: `/user/space`,
  getAllSpaces: (offset: number) =>
    `/user/space/testSpaces?offset=${offset}&limit=${100}`,
  // getAllSpaces: (offset: number) =>
  //   `/user/space?offset=${offset}&limit=${DATA_LIMIT}`,
  getSpaceById: (spaceId: string) => `/user/space/${spaceId}`,
  updateSpaceById: (spaceId: string) => `/user/space/${spaceId}`,
  deleteSpaceById: (spaceId: string) => `/user/space/${spaceId}`,

  addReceiver: (spaceId: string) => `/user/space/receiver/${spaceId}`,
  getReceiversBySpace: (spaceId: string) => `/user/space/receiver/${spaceId}`,
  getReceiverById: (receiverId: string) =>
    `/user/space/receiver/${receiverId}/getOne`,
  deleteReceiverFromSpace: (receiverId: string) =>
    `/user/space/receiver/${receiverId}`,
  renameReceiver: (receiverId: string) =>
    `/user/space/receiver/${receiverId}/rename`,
  changeReceiverAccess: (receiverId: string) =>
    `/user/space/receiver/${receiverId}/changeAccess`,
  changeReceiverAccessFromNotification: (spaceNotificationId: string) =>
    `/user/space/receiver/${spaceNotificationId}/receiverStatus`,
  regenerateReceiverLink: (receiverId: string) =>
    `/user/space/receiver/${receiverId}/regeneratedLink`,
  receiverStatusUpdate: (receiverId: string) =>
    `/user/space/receiver/${receiverId}/receiverStatus`,

  // RECEIVER ENDPOINTS
  getReceiverSpaceList: `/user/receiver/users/getAll`,
  getReceiverFilesById: (receiverId: string) =>
    `/user/receiver/${receiverId}/receiverFile`,
  checkValidAccessToSpace: (receiverId: string) =>
    `/user/receiver/${receiverId}/checkValidAccessToSpace`,
  checkValidAccessToGuest: (receiverId: string) =>
    `/user/receiver/${receiverId}/checkValidAccessToGuest`,
  checkValidAccessShareSpace: (spaceId: string) =>
    `/user/receiver/${spaceId}/checkValidAccessShareSpace`,
  checkValidGuestAccessShareSpace: (spaceId: string) =>
    `/user/receiver/${spaceId}/checkValidGuestAccessShareSpace`,
  checkValidAccessShareFile: (bulkId: string) =>
    `/user/receiver/${bulkId}/checkValidAccessShareFile`,
  checkValidGuestAccessShareFile: (bulkId: string) =>
    `/user/receiver/${bulkId}/checkValidGuestAccessShareFile`,
  deleteSenderSpace: (receiverId: string) =>
    `/user/receiver/receiverSpace/${receiverId}`,
  convertGuestToUser: (receiverId: string) =>
    `/user/receiver/guest/${receiverId}/update`,

  // SPACE FILES ENDPOINTS
  spaceFileUpload: (spaceId: string) => `/user/spaceFile/${spaceId}`,
  getFilesBySpaceId: (spaceId: string) => `/user/spaceFile/${spaceId}`,
  deleteFileByFileId: (fileId: string) => `/user/spaceFile/${fileId}`,
  getTotalStorageSpace: `/user/spaceFile/space/totalstorage`,
  updateFileStatus: (fileId: string) =>
    `/user/spaceFile/${fileId}/changeAccess`,

  // USER PROFILE ENDPOINTS
  getUserProfile: `/user/profile`,
  getUserPlan: `/user/profile/plan`,
  changePassword: `/user/profile/passwordUpdate`,
  changePlan: (planId: string) => `/user/profile/${planId}/changePlan`,
  updateProfile: `/user/profile/update`,
  deleteProfile: `/user/profile/withdrawSpace`,
  changeSecretCode: `/user/profile/changeSecretCode`,

  // PAYMENT ENDPOINTS
  getPaymentCards: `/user/creditCard`,
  addPaymentCard: (paymentMethodId: string) =>
    `/user/creditCard/creditCard/${paymentMethodId}`,
  setDefaultCard: (paymentMethodId: string) =>
    `/user/creditCard/creditCard/${paymentMethodId}/default`,
  deletePaymentCard: (paymentMethodId: string) =>
    `/user/creditCard/creditCard/${paymentMethodId}`,

  // NOTIFICATION ENDPOINTS
  getNotificationStatus: `/user/notification`,
  notificationStatusUpdate: `/user/notification`,
  getSpaceAccessNotifications: (offset: number) =>
    `/user/notification/spaceAccessNotification?offset=${offset}&limit=20`,
  getNotificationById: (notificationId: string) =>
    `/user/notification/spaceAccessNotification/${notificationId}/getOne`,
  clearAllNotifications: `/user/notification/spaceAccessNotification/clearAll`,
};

export default API_ROUTES;
