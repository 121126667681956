import React, { useState } from 'react';
import { Button } from '@willeder/component-library';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/atoms/CheckBox';
import useModalStore from 'store/useModalStore';
import styles from './NewSpaceConfirmModal.module.scss';

const NewSpaceConfirmModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { closeModal, setOpenModal } = useModalStore();
  const [isSkip, setSkip] = useState(false);

  const onGotoUpload = () => {
    navigate(`/sender-space/temp-space`);
    closeModal();
  };

  return (
    <div className={styles.confirmModal}>
      <h1 className={styles.title}>{t('labels.skipUploadingProcess')}</h1>
      <p className={styles.Subtitle}>
        {t('labels.confirmSkipUploadingProcess')}
      </p>
      <div className={styles.buttonWrapper}>
        <Button className="update" onClick={onGotoUpload}>
          {t('buttons.gotoUpload')}
        </Button>
        <Button
          className="update"
          onClick={() => {
            localStorage.setItem(
              'isSkipUploadModal',
              isSkip ? 'true' : 'false'
            );
            setOpenModal('createSpace');
          }}
        >
          {t('buttons.skip')}
        </Button>
        <Button className="close" onClick={closeModal}>
          {t('buttons.close')}
        </Button>
        {/* <CheckBox
          label={t('labels.skipAllTheTime')}
          checked={isSkip}
          onChange={() => setSkip(!isSkip)}
        /> */}
      </div>
    </div>
  );
};

export default NewSpaceConfirmModal;
