import React from 'react';
import Modal from 'components/atoms/Modal';
import useModalStore from 'store/useModalStore';
import AccountInfoModal from 'components/molecules/AccountInfoModal';
import ChangePasswordModal from 'components/molecules/ChangePasswordModal';
import GenerateCodeModal from 'components/molecules/GenerateCodeModal';
import ConfirmModal from 'components/molecules/ConfirmModal';
import AddSpaceModal from 'components/molecules/AddSpaceModal';
import EditSpaceModal from 'components/molecules/EditSpaceModal';
import NewUserFileUploadModal from 'components/molecules/NewUserFileUploadModal';
import ReceiverLinkModal from 'components/molecules/ReceiverLinkModal';
import AddReceiverModal from 'components/molecules/AddReceiverModal';
import SubscriptionModal from 'components/molecules/SubscriptionModal';
import PaymentCardsModal from 'components/molecules/PaymentCardsModal';
import AddPaymentCardsModal from 'components/molecules/AddPaymentCardModal';
import SuccessModal from 'components/molecules/SuccessModal';
import NewSpaceConfirmModal from 'components/molecules/NewSpaceConfirmModal';
import FileUpload from 'components/molecules/FileUploadModal';
import NotificationModal from '../NotificationModal';
import { GlobalModals, ModalComponentProps } from 'store/@types/modalStore';
import TermsAndConditionsModal from 'components/molecules/TermsAndConditionsModal';
import TransferReadmeModal from 'components/molecules/TransferReadmeModal';
import FilesProgressModal from 'components/molecules/FilesProgressModal';
import GuestToUserModal from 'components/molecules/GuestToUserModal';
import DuplicateTabModal from 'components/molecules/DuplicateTabModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/cross.svg';

const GLOBAL_MODALS: Record<
  GlobalModals,
  React.ComponentType<ModalComponentProps>
> = {
  newUserFileUpload: NewUserFileUploadModal,
  account: AccountInfoModal,
  changePassword: ChangePasswordModal,
  secretCode: GenerateCodeModal,
  confirmModal: ConfirmModal,
  createSpace: AddSpaceModal,
  editSpace: EditSpaceModal,
  receiverCryptoLink: ReceiverLinkModal,
  addReceiver: AddReceiverModal,
  subscriptionModal: SubscriptionModal,
  userPaymentCards: PaymentCardsModal,
  addPaymentCard: AddPaymentCardsModal,
  notificationModal: NotificationModal,
  successModal: SuccessModal,
  addSpaceConfirmModal: NewSpaceConfirmModal,
  fileUploadModal: FileUpload,
  termsAndConditions: TermsAndConditionsModal,
  spaceReadme: TransferReadmeModal,
  fileProgressModal: FilesProgressModal,
  guestToUserModal: GuestToUserModal,
  duplicateTabModal: DuplicateTabModal,
};

const GlobalModal = () => {
  const { openModal, closeModal, modalProps, modalType, confirmModalProps } =
    useModalStore((state) => state);
  const ModalComponent: React.ComponentType<any> | null = openModal
    ? GLOBAL_MODALS[openModal]
    : null;

    const shouldShowCloseButton = openModal
    ? ['subscriptionModal', 'termsAndConditions'].includes(openModal)
    : false;

  if (modalType === 'confirm' && confirmModalProps)
    return <ConfirmModal {...confirmModalProps} />;

  return (
    <Modal
      isOpen={!!openModal}
      onClose={() => {
        openModal &&
          [
            'subscriptionModal',
            'successModal',
            'termsAndConditions',
            'notificationModal',
          ].includes(openModal) &&
          closeModal();
      }}
      closeIcon={<CloseIcon />}
      showCloseButton={shouldShowCloseButton}
    >
      {ModalComponent && <ModalComponent {...(modalProps || {})} />}
    </Modal>
  );
};

export default GlobalModal;
