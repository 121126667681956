import React, { ReactNode, useEffect, useRef } from "react"
import "./Modal.scss"
import { ReactComponent as CloseIcon } from '../../../assets/icons/cross.svg';

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  closeIcon?: ReactNode
  children: React.ReactNode
  showCloseButton?: boolean // Optional prop to show an internal close button
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  closeIcon,
  children,
  showCloseButton = false,
}) => {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null

  return (
    <div className="willeder-modal-overlay-scope">
      <div className="willeder-modal-scope" ref={modalRef!}>
        {showCloseButton && (
          <button className="willeder-modal-close-scope" onClick={()=>{
            onClose()
            console.log("===> triggger")
          }}>
            {closeIcon ? closeIcon : <CloseIcon />}
          </button>
        )}
        <div className="willeder-modal-content-scope">{children}</div>
      </div>
    </div>
  )
}

export default Modal