import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile.svg';
import { ReactComponent as TickIcon } from '../../../assets/icons/tick_green.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/close.svg';
import styles from './NotificationItems.module.scss';

interface NotificationItemProps {
  name: string;
  time: string;
  onAccept: () => void;
  onReject: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  name,
  time,
  onAccept,
  onReject,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.notificationItem}>
      <div className={styles.leftContent}>
        <ProfileIcon className={styles.profileIcon} />
        <div className={styles.textContainer}>
          <p className={styles.name}>{name}</p>
          <p className={styles.time}>{time}</p>
          <p className={styles.requestText}>{t('labels.requestSent')}</p>
        </div>
      </div>
      <div className={styles.rightContent}>
        <TickIcon onClick={onAccept} />
        <CrossIcon onClick={onReject} />
      </div>
    </div>
  );
};

export default NotificationItem;
