import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@willeder/component-library';
import { bytesToSize, truncateFileName } from 'utils/helper';
import { DragDropModalProps } from 'types/modals';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-deactive.svg';
import { ReactComponent as UploadActive } from '../../../assets/icons/upload-file.svg';
import { ReactComponent as DragDropIcon } from '../../../assets/icons/drag-drop.svg';
import { ReactComponent as FileIcon } from '../../../assets/icons/document.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import styles from './DragDropModal.module.scss';
import useAlert from 'store/useAlert';
import userSpaceStore from 'store/userSpaceStore';
import { useTranslation } from 'react-i18next';
import Modal from 'components/atoms/Modal';

const FREE_PLAN_LIMIT = 200 * 1024 * 1024;
const PAID_PLAN_LIMIT = 1 * 1024 * 1024 * 1024;

const DragDropModal: React.FC<DragDropModalProps> = ({
  files,
  setFiles,
  onFilesUploadToStorage,
  onCancel,
}) => {
  const [totalSize, setTotalSize] = useState(0);
  const [availableSpace, setAvailableSpace] = useState(0);
  const [duplicateFiles, setDuplicateFiles] = useState<File[]>([]);
  const [showOverwriteModal, setShowOverwriteModal] = useState(false);
  const setAlert = useAlert((state) => state.setAlert);
  const { spaceSizeData } = userSpaceStore((state) => state);
  const { t } = useTranslation();

  useEffect(() => {
    if (spaceSizeData) {
      const available =
        spaceSizeData.totalStorageSize * 1024 * 1024 -
        spaceSizeData.totalFileSize;
      setAvailableSpace(available);
    }
  }, [spaceSizeData]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      let total = totalSize;
      const newFiles: File[] = [];
      const duplicates: File[] = [];

      acceptedFiles.forEach((newFile) => {
        const isDuplicate = files?.some(
          (existingFile) => existingFile.name === newFile.name
        );
        if (isDuplicate) {
          duplicates.push(newFile); 
        } else {
          newFiles.push(newFile);
          total += newFile.size;
        }
      });

      if (duplicates.length > 0) {
        setDuplicateFiles(duplicates); 
        setShowOverwriteModal(true);
      }

      if (
        (spaceSizeData?.planType === 'free' &&
          total <= FREE_PLAN_LIMIT &&
          total <= availableSpace) ||
        (spaceSizeData?.planType === 'paid' &&
          total <= PAID_PLAN_LIMIT &&
          total <= availableSpace)
      ) {
        setTotalSize(total);
        setFiles([...newFiles, ...(files || [])]);
      } else {
        setAlert('File size limit exceeded or not enough storage space');
      }
    },
    [files, setFiles, totalSize, spaceSizeData?.planType, availableSpace]
  );

  const handleOverwriteConfirm = () => {
    if (duplicateFiles.length > 0) {
      const updatedFiles = files?.filter(
        (file) =>
          !duplicateFiles.some((duplicate) => duplicate.name === file.name)
      );
      setFiles([...(updatedFiles || []), ...duplicateFiles]);
      setTotalSize(
        totalSize + duplicateFiles.reduce((acc, file) => acc + file.size, 0)
      );
      setDuplicateFiles([]);
      setShowOverwriteModal(false);
    }
  };

  const handleAddDuplicateFiles = () => {
    if (duplicateFiles.length > 0) {
      setFiles([...duplicateFiles, ...(files || [])]);
      setTotalSize(
        (prevSize) =>
          prevSize + duplicateFiles.reduce((acc, file) => acc + file.size, 0)
      );
      setDuplicateFiles([]);
      setShowOverwriteModal(false);
    }
  };

  const handleOverwriteCancel = () => {
    setDuplicateFiles([]);
    setShowOverwriteModal(false);
  };

  const deleteFile = (index: number) => {
    const updatedFiles = files?.filter((_, i) => i !== index);
    updatedFiles && setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noKeyboard: true,
  });

  return (
    <>
      <div
        className="dragDropModal"
        style={{ position: 'absolute', width: '100%', top: 0, bottom: 0 }}
      >
        <div className={styles.wrapper}>
          <div className={styles.dragWrapper}>
            <div className={styles.container}>
              <div
                {...getRootProps({
                  className: `${styles.dropzone} ${files && files.length > 0 ? styles.withFiles : ''}`,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <>
                    <DragDropIcon />
                    <p>{t('labels.dropFiles')}</p>
                  </>
                ) : files && files.length > 0 ? (
                  <div className={styles.afterWrapper}>
                    <span className={styles.icon}>
                      <DragDropIcon />
                    </span>
                    <div className={styles.afterDrag}>
                      {t('labels.selectOrDropFiles')}
                      <Button className="selectButton_after ">Select</Button>
                    </div>
                  </div>
                ) : (
                  <div className={styles.before}>
                    <DragDropIcon />
                    <p>{t('labels.selectOrDropFiles')}</p>
                    <Button className="selectButton capitalize">
                      {t('buttons.select')}
                    </Button>
                    <Button
                      className="cancelButton mw280 alterText"
                      onClick={(e) => {
                        e.stopPropagation();
                        onCancel();
                      }}
                    >
                      {t('buttons.cancel')}
                    </Button>
                  </div>
                )}
              </div>
              {files && files.length > 0 && (
                <aside
                  {...getRootProps({
                    className: `${styles.dropzone} ${files.length > 0 ? styles.withFiles : ''}`,
                  })}
                >
                  <div className={styles.heading}>
                    <h4>
                      {files.length} {t('labels.filesAdded')}
                    </h4>
                  </div>
                  <div className={styles.lists}>
                    <ul>
                      {files.map((uploadedFile, index) => (
                        <li key={index}>
                          <span className={styles.icon}>
                            <FileIcon />
                          </span>
                          <div className={styles.fileInfo}>
                            <p className={styles.fileName}>
                              {truncateFileName(uploadedFile.name)}
                            </p>
                            <span className={styles.right}>
                              <p>{bytesToSize(uploadedFile.size)}</p>
                              <DeleteIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteFile(index);
                                }}
                              />
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </aside>
              )}
            </div>
            {files?.length ? (
              <div className={styles.buttonWrapper}>
                <Button
                  className={`upload ${files.length > 0 ? 'activeUpload' : ''}`}
                  icon={files.length > 0 ? <UploadActive /> : <UploadIcon />}
                  iconPosition="right"
                  disabled={!files.length}
                  onClick={onFilesUploadToStorage}
                >
                  {t('buttons.upload')}
                </Button>
                <Button className="cancelButton" onClick={onCancel}>
                  {t('buttons.cancel')}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {showOverwriteModal && (
        <Modal isOpen={showOverwriteModal} onClose={handleOverwriteCancel}>
          <div className="receiverModal">
            <p>{t('labels.overwritePrompt')}</p>
            <div className="buttonWrapper">
              <Button className="update" onClick={handleOverwriteConfirm}>
                {t('buttons.overwrite')}
              </Button>
              {/*<Button className="close" onClick={handleAddDuplicateFiles}>
                {t('buttons.addDuplicateFiles')}
              </Button> */}
              <Button className="close" onClick={handleOverwriteCancel}>
                {t('buttons.cancel')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DragDropModal;
