import React from 'react';
import { Navigate } from 'react-router-dom';
import HeaderNavbar from 'components/molecules/NavBar';
import { getCookies } from 'utils/cookies';

interface ProtectedRoutesProps {
  children: JSX.Element;
}

const ProtectedRoutes = ({ children }: ProtectedRoutesProps) => {
  const isAuthenticated = getCookies('accessToken');

  return isAuthenticated ? (
    <>
      <HeaderNavbar />
      {children}
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoutes;
