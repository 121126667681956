import useSWR, { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import { useCryptoLink } from '../encryption/useCryptoLink';
import userSpaceStore from 'store/userSpaceStore';

const useSpace = (spaceId?: string) => {
  const { post, put, del, get } = useAPI();
  const { generateLink } = useCryptoLink();
  const setCurrentSpaceId = userSpaceStore((state) => state.setCurrentSpaceId);

  const {
    data: spaceData,
    error,
    isLoading,
    mutate: getSpaceData,
  } = useSWR<SpaceByIdResponse>(
    spaceId ? API_ROUTES.getSpaceById(spaceId) : null,
    get,
    {
      revalidateOnFocus: false,
      // revalidateIfStale: false,
      errorRetryCount: 1,
    }
  );

  const {
    data: spaceReceivers,
    error: receiverError,
    isLoading: receiverLoading,
    mutate: getSpaceReceiver,
  } = useSWR<SpaceReceiversResponse>(
    spaceId ? API_ROUTES.getReceiversBySpace(spaceId) : null,
    get,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: false,
      errorRetryCount: 3,
    }
  );

  const createNewSpace = async (
    url: string,
    { arg }: { arg: Partial<SpacePayload> }
  ) => {
    const response: SpaceCreateResponse = await post(url, arg);
    const { receiverId } = response.data;
    setCurrentSpaceId(response.data.spaceId);
    mutate(API_ROUTES.getAllSpaces(0));
    const link =
      receiverId && generateLink(receiverId, response.data.transferKey);
    return {
      ...response,
      data: {
        ...response.data,
        link: link,
      },
    };
  };

  const {
    trigger: createSpace,
    isMutating: creating,
    error: createError,
  } = useSWRMutation(API_ROUTES.createSpace, createNewSpace);

  const sendUpdateRequest = async (
    url: string,
    { arg }: { arg: Partial<SpacePayload> }
  ) => {
    const response: ApiResponse = await put(url, arg);
    mutate(API_ROUTES.getAllSpaces(0));
    return response;
  };

  const {
    trigger: updateSpace,
    isMutating: updating,
    error: updateError,
  } = useSWRMutation(
    spaceId ? API_ROUTES.updateSpaceById(spaceId) : null,
    sendUpdateRequest
  );

  const sendDeleteRequest = async (url: string) => {
    const response: ApiResponse = await del(url);
    mutate(API_ROUTES.getAllSpaces(0));
    return response;
  };

  const {
    trigger: deleteSpace,
    isMutating: deleting,
    error: deleteError,
  } = useSWRMutation(
    spaceId ? API_ROUTES.deleteSpaceById(spaceId) : null,
    sendDeleteRequest
  );

  return {
    getSpaceData,
    spaceData,
    spaceReceivers,
    getSpaceReceiver,
    createSpace,
    updateSpace,
    deleteSpace,
    sendDeleteRequest,
    isLoading: isLoading || creating || updating || deleting,
    error: error || createError || updateError || deleteError,
    receiverError,
    receiverLoading,
  };
};

export default useSpace;
