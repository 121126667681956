import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useForgotPassword from 'hooks/auth/useForgotPassword';
import useAlert from 'store/useAlert';
import useAuthStore from 'store/authStore';
import { getErrorMessage, getSuccessMessage } from 'utils/helper';
import { emailRegex } from 'utils/constant';
import Logo from '../../assets/icons/logo.svg';
import styles from './ForgotPassword.module.scss';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setAlert = useAlert((state) => state.setAlert);
  const setEmail = useAuthStore((state) => state.setEmail);
  const { onForgotPassword, isLoading } = useForgotPassword();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t(getErrorMessage('3004')))
      .required(t(getErrorMessage('3003')))
      .matches(emailRegex, t(getErrorMessage('3004')))
      .max(255, t('errors.maxCharacters', { max: '255' })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSendEmail = (data: ForgotPasswordPayload) => {
    setEmail(data.email);
    onForgotPassword(data)
      .then((response) => {
        if (response.success) setAlert(t(getSuccessMessage('8001')), 'success');
        navigate('/forgot-password/otp-code');
      })
      .catch((error: ResponseError) =>
        setAlert(t(getErrorMessage(error.subStatusCode)))
      );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <img src={Logo} alt="Logo" />
        <h1>{t('common.forgotPassword')}</h1>
      </div>
      <Input
        label={t('fields.email')}
        placeholder={t('placeholders.email')}
        inputProps={{
          ...register('email'),
          type: 'email',
        }}
        errorMessage={errors.email?.message}
      />
      <Button
        onClick={handleSubmit(onSendEmail)}
        disabled={isLoading}
        isLoading={isLoading}
        className="test"
      >
        {t('buttons.sendEmail')}
      </Button>
    </div>
  );
};

export default ForgotPassword;
