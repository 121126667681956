import useSWRMutation from 'swr/mutation';
import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';

const useChangePassword = () => {
  const { put } = useAPI();

  const sendChangePasswordRequest = async (
    url: string,
    { arg }: { arg: ChangePasswordPaylod }
  ) => {
    const response: ApiResponse = await put(url, arg);
    return response;
  };

  const { trigger, isMutating, error } = useSWRMutation(
    API_ROUTES.changePassword,
    sendChangePasswordRequest
  );

  return {
    onChangePassword: trigger,
    isLoading: isMutating,
    error,
  };
};

export default useChangePassword;
