import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      isAuthenticated: false,
      isNewUser: false,
      userId: '',
      secretKey: '',
      secretSalt: '',
      loginToken: '',
      locale: 'ja',
      email: '',
      otpId: '',
      name: '',
      password: '',
      planId: '',
      paymentMethodId: '',

      setIsAuthenticated: (data) => {
        set({ isAuthenticated: data });
      },

      setAuthInformation: (data) => {
        set({
          isNewUser: data.isNewUser,
          userId: data.userId,
          secretKey: data.secretKey,
          secretSalt: data.secretSalt,
          loginToken: data.loginToken,
        });
      },

      setLocale: (data) => set({ locale: data }),

      setEmail: (email) => set({ email: email }),

      setOTPId: (id) => set({ otpId: id }),

      setUserInfo: (data) =>
        set({ name: data.name, password: data.password, email: data.email }),

      setNewUser: (isNewUser) => set({ isNewUser }),

      setPlanId: (planId) => set({ planId: planId }),

      setPaymentMethodId: (paymentMethodId) => set({ paymentMethodId }),
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useAuthStore;
