import React, { useEffect, useState } from 'react';
import AlertMessage from 'components/atoms/Alert';
import useAlert from 'store/useAlert';

const ToastNotification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { message, clearAlert, variant } = useAlert((state) => state);

  useEffect(() => {
    if (message) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
        clearAlert();
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [message]);

  return isVisible ? <AlertMessage message={message} type={variant} /> : null;
};

export default ToastNotification;
