import useSWRMutation from 'swr/mutation';
import API_ROUTES from 'services/apiRoutes';
import useAuthStore from 'store/authStore';
import { setCookies } from 'utils/cookies';
import { useAPI } from '../useAPI';

const useLogin = () => {
  const { put } = useAPI();
  const setAuthInformation = useAuthStore((state) => state.setAuthInformation);

  const sendLoginRequest = async (
    url: string,
    { arg }: { arg: LoginPayload }
  ) => {
    const response: LoginResponse = await put(url, arg);
    const { userId, secretKey, secretSalt, loginToken } = response.result;

    const authInformation = {
      userId,
      secretKey,
      secretSalt,
      loginToken: loginToken,
    };
    setCookies('loginToken', loginToken);
    setAuthInformation(authInformation);
    return response;
  };

  const { trigger, isMutating, error } = useSWRMutation(
    API_ROUTES.login,
    sendLoginRequest
  );

  return {
    onLogin: trigger,
    isLoading: isMutating,
    error,
  };
};

export default useLogin;
