// FileUploadModal.js
import React, { useState, useEffect, MutableRefObject, useRef } from 'react';
import { Button } from '@willeder/component-library';
import { bytesToSize } from 'utils/helper';
import styles from './FilesProgressModal.module.scss';
import useModalStore from 'store/useModalStore';
import useFileStore from 'store/useFileStore';
import { useTranslation } from 'react-i18next';
import Loader from 'components/atoms/Loader';

export interface FileProcessProps {
  fileList: AccordionItem[];
  progress: MutableRefObject<number | undefined>;
  mode: 'download' | 'delete';
}

interface FileInfo {
  file: AccordionItem;
  status: string;
}

const FilesProgressModal: React.FC<FileProcessProps> = () => {
  const [completed, setCompleted] = useState<boolean>(false);
  const { closeModal } = useModalStore();
  const {
    fileList,
    mode,
    setLoading,
    progressPercentage,
    currentStatusIndex,
    currentFileIndex,
    setIsCancelled,
    clearStoreData,
  } = useFileStore();
  const [customProgress, setCustomProgress] = useState(0);
  const closeTimeoutRef = useRef<number>();
  const { t } = useTranslation();
  const filesRef = useRef<FileInfo[]>([]);

  useEffect(() => {
    if (fileList) {
      const initializedFiles = fileList.map((file) => ({
        file: file,
        status: t('common.waiting'),
      }));
      filesRef.current = initializedFiles;
    }
  }, [fileList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (customProgress < 100 && progressPercentage < 100) {
        setCustomProgress((prevProgress) => {
          const newProgress =
            progressPercentage > prevProgress + 1
              ? progressPercentage
              : prevProgress + 1;
          return newProgress > 98 ? 98 : newProgress;
        });
      } else {
        closeTimeoutRef.current = window.setTimeout(() => {
          closeModal();
          clearStoreData();
        }, 200);
        setCustomProgress(100);
        clearInterval(interval);
        setCompleted(true);
        setLoading(false);
      }
    }, 100);

    return () => {
      clearInterval(interval);
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, [customProgress, progressPercentage, closeModal, setLoading]);

  useEffect(() => {
    const status =
      mode === 'download'
        ? [t('common.downloading'), t('common.decrypting'), t('common.done')][
            currentStatusIndex
          ]
        : [t('common.deleting'), t('common.done')][currentStatusIndex];
    const updatedFiles = filesRef.current?.map((file, idx) =>
      idx === currentFileIndex ? { file: file.file, status } : file
    );
    filesRef.current = updatedFiles;
  }, [currentFileIndex, currentStatusIndex, mode, t]);

  return (
    <div className={styles.fileModal}>
      <h1 className={styles.title}>
        {mode === 'download' ? t('common.downloading') : t('common.deleting')}
      </h1>
      <div className={styles.fileWrapper}>
        <meter
          className={styles.progressBar}
          value={customProgress}
          min={0}
          max={100}
        ></meter>
        <div className={styles.uploadInfo}>
          {`${customProgress?.toFixed(0)}% ${mode === 'delete' ? t('common.deleting') : t('common.downloading')} `}{' '}
          <Loader />
        </div>
      </div>
      <div className={styles.wrapper}>
        {filesRef.current?.map((fileInfo, index) => (
          <div className={styles.fileInfo} key={index}>
            <span className={styles.fileName}>{`${fileInfo.file?.title}`}</span>
            <span className={styles.size}>
              {bytesToSize(fileInfo?.file?.fileSize)}
            </span>
            <span className={styles.status}>{fileInfo.status}</span>
          </div>
        ))}
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          className="close"
          onClick={() => {
            setIsCancelled(true);
            setTimeout(() => {
              closeModal();
            }, 500);
          }}
          disabled={completed}
        >
          {t('buttons.cancel')}
        </Button>
      </div>
    </div>
  );
};

export default FilesProgressModal;
