import JA_BUTTONS from './buttons.json';
import JA_COMMON from './common.json';
import JA_ERRORS from './errors.json';
import JA_FIELDS from './fields.json';
import JA_LABELS from './labels.json';
import JA_LINKS from './links.json';
import JA_NAVIGATIONS from './navOptions.json';
import JA_PLACEHOLDERS from './placeholders.json';
import JA_SUCCESS from './success.json';
import JA_TITLES from './titles.json';

export const translations = {
  buttons: JA_BUTTONS,
  common: JA_COMMON,
  errors: JA_ERRORS,
  fields: JA_FIELDS,
  labels: JA_LABELS,
  links: JA_LINKS,
  navOptions: JA_NAVIGATIONS,
  placeholders: JA_PLACEHOLDERS,
  success: JA_SUCCESS,
  titles: JA_TITLES,
};
