import React from 'react';
import { Button } from '@willeder/component-library';
import styles from './GuestToUserModal.module.scss';
import useModalStore from 'store/useModalStore';
import { useTranslation } from 'react-i18next';

const GuestToUserModal = () => {
  const { t } = useTranslation();
  const { closeModal } = useModalStore((state) => state);

  return (
    <div className={styles.confirmModal}>
      <div className={styles.description}>
        {t('success.guestToUserSuccess')}
      </div>
      <Button className="close" onClick={closeModal}>
        {t('buttons.close')}
      </Button>
    </div>
  );
};

export default GuestToUserModal;
