import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_ROUTES from 'services/apiRoutes';
import useAuthStore from 'store/authStore';
import useModalStore from 'store/useModalStore';
import useAlert from 'store/useAlert';
import { removeCookies } from 'utils/cookies';
import { getErrorMessage } from 'utils/helper';
import useProfile from './profile/useProfile';
import { useSecretCode } from './encryption/useSecretCode';
import useProfileSecretCode from './profile/useProfileSecretCode';
import { useAPI } from './useAPI';
import { mutate } from 'swr';
import useNotificationSocket from './useNotificationSocket';

const useNavigationActions = () => {
  const navigate = useNavigate();
  const { put } = useAPI();
  const { t } = useTranslation();

  const {
    setIsAuthenticated,
    userId,
    secretKey,
    secretSalt,
    locale,
    setLocale,
  } = useAuthStore((state) => state);
  const { closeModal, setConfirmModalProps, setOpenModal, setModalProps } =
    useModalStore((state) => state);
  const { getProfileData } = useProfile();
  const setAlert = useAlert((state) => state.setAlert);
  const { getSecretCode, getNewSecretAuthCode } = useSecretCode();
  const { changeSecretCode, isLoading: secretCodeLoading } =
    useProfileSecretCode();
  const { onDisconnectSocket } = useNotificationSocket();

  const onEditAccount = () => {
    getProfileData();
    setOpenModal('account');
  };

  const onChangePassword = () => setOpenModal('changePassword');

  const onEditSpace = () => setOpenModal('editSpace');

  const openSecretCode = () => {
    setOpenModal('secretCode');
    const secretCode = getSecretCode(secretKey);
    setModalProps({
      secretCode: secretCode,
      onClose: closeModal,
    });
  };

  const onResetSecretCode = () => {
    const { secretAuth, encryptedSecretCode } = getNewSecretAuthCode(
      userId,
      secretKey,
      secretSalt
    );
    if (secretAuth) {
      changeSecretCode({
        secretAuth: secretAuth,
      })
        .then((response) => {
          if (response.success) {
            localStorage.setItem('encryptedSecretCode', encryptedSecretCode);
            setIsAuthenticated(true);
            closeModal();
            openSecretCode();
          }
        })
        .catch((error) => setAlert(t(getErrorMessage(error.subStatusCode))));
    }
  };

  const openResetSecretCode = () => {
    setOpenModal('confirmModal', 'confirm');

    setConfirmModalProps({
      title: 'Reset Secret Code',
      content: t('labels.resetAccountConfirmation'),
      cancel: {
        children: t('buttons.no'),
        onClick: () => closeModal(),
      },
      confirm: {
        children: t('buttons.yes'),
        onClick: () => onResetSecretCode(),
      },
      isOpen: true,
      isLoading: secretCodeLoading,
    });
  };

  const handleLogout = () => {
    put(API_ROUTES.logout, {})
      .then(() => {
        closeModal();
        // setIsAuthenticated(false);
        removeCookies('accessToken');
        removeCookies('refreshToken');
        removeCookies('loginToken');
        localStorage.removeItem('encryptedSecretCode');
        localStorage.removeItem('auth-storage');
        navigate('/');
        mutate(() => true, undefined, false);
        onDisconnectSocket();
      })
      .catch((e) => console.log(e));
  };

  const onLogout = () => {
    setOpenModal('confirmModal', 'confirm');
    setConfirmModalProps({
      title: t('titles.logout'),
      content: t('labels.logout'),
      cancel: {
        children: t('buttons.no'),
        onClick: () => closeModal(),
      },
      confirm: {
        children: t('buttons.yes'),
        onClick: () => handleLogout(),
      },
      isOpen: true,
    });
  };

  const onOpenSubscriptionPlans = () => {
    setOpenModal('subscriptionModal');
  };

  const onOpenPaymentCards = () => {
    setOpenModal('userPaymentCards');
  };

  const onChangeLanguage = () => {
    localStorage.setItem('locale', locale === 'en' ? 'ja' : 'en');
    setLocale(locale === 'en' ? 'ja' : 'en');
  };

  return {
    onLogout,
    onEditSpace,
    onEditAccount,
    onChangePassword,
    openSecretCode,
    openResetSecretCode,
    onOpenSubscriptionPlans,
    onOpenPaymentCards,
    onChangeLanguage,
  };
};

export default useNavigationActions;
