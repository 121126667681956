import React, { useState, useEffect } from 'react';
import { bytesToSize } from 'utils/helper';
import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  totalStorage: number;
  fileUploadSize: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  totalStorage,
  fileUploadSize,
}) => {
  // const [usedStorage, setUsedStorage] = useState(0);

  // useEffect(() => {
  //   let uploadInterval: any;
  //   if (fileUploadSize > 0 && !isNaN(fileUploadSize)) {
  //     const progressIncrement = fileUploadSize / 100;
  //     uploadInterval = setInterval(() => {
  //       setUsedStorage((prevUsedStorage) => {
  //         const nextUsedStorage = prevUsedStorage + progressIncrement;
  //         if (nextUsedStorage >= fileUploadSize) {
  //           clearInterval(uploadInterval);
  //           return fileUploadSize;
  //         }
  //         return nextUsedStorage;
  //       });
  //     }, 100);
  //   }

  //   return () => clearInterval(uploadInterval);
  // }, [fileUploadSize]);

  const storageText = `${bytesToSize(fileUploadSize)} used out of ${bytesToSize(totalStorage)}`;
  const storageRatio = `${bytesToSize(fileUploadSize)} / ${bytesToSize(totalStorage)}`;

  return (
    <div className={styles.progressbar}>
      <p>{storageRatio}</p>
      <meter
        className={styles.uploadMeter}
        value={fileUploadSize}
        min="0"
        max={totalStorage}
      >
        {storageText}
      </meter>
    </div>
  );
};

export default ProgressBar;
