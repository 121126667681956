import { create } from 'zustand';

interface AlertState {
  message: string;
  variant: 'error' | 'success' | 'info';
  setAlert: (message: string, variant?: 'error' | 'success' | 'info') => void;
  clearAlert: () => void;
}

const useAlert = create<AlertState>((set) => ({
  message: '',
  variant: 'error',

  setAlert: (message, variant = 'error') => {
    set({ message: message, variant: variant });
  },

  clearAlert() {
    set({ message: '' });
  },
}));

export default useAlert;
