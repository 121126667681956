import useSWR from 'swr';
import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import useSWRMutation from 'swr/mutation';

const useUserSpaces = (offset: number = 0) => {
  const { get, put } = useAPI();

  const {
    data: userSpaces,
    error,
    isLoading,
    mutate,
  } = useSWR<DashboardSpaceApiResponse>(API_ROUTES.getAllSpaces(offset), get, {
    revalidateOnFocus: false,
    // revalidateIfStale: false,
  });

  const createNewSpace = async (
    url: string,
    { arg }: { arg: Partial<SpacePayload> }
  ) => {
    const response: ApiResponse = await put(url, arg);
    mutate();
    return response;
  };

  const {
    trigger: createSpace,
    isMutating: creating,
    error: createError,
  } = useSWRMutation(API_ROUTES.createSpace, createNewSpace);

  return {
    userSpaces,
    createSpace,
    getSpaceList: mutate,
    isLoading: isLoading || creating,
    error: error || createError,
  };
};

export default useUserSpaces;
