import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReceiverSidebar from 'components/organism/SideBar/ReceiverSidebar';
import FileListLayout from 'components/organism/FileListLayout';
import useReceiverSpace from 'hooks/receiver/useReceiverSpace';
import userSpaceStore from 'store/userSpaceStore';
import styles from './ProtectedReceiverSpace.module.scss';
import Modal from 'components/atoms/Modal';
import { getErrorMessage } from 'utils/helper';
import { useTranslation } from 'react-i18next';
import { io } from 'socket.io-client';
import useModalStore from 'store/useModalStore';

const ReceiverSpace = () => {
  const { receiverId } = useParams<{ receiverId: string }>();
  const { t } = useTranslation();
  const { receiverData, setReceiverId, setReceiverData } = userSpaceStore(
    (state) => state
  );
  const { setOpenModal } = useModalStore();
  const { receiverFiles, receiverError } = useReceiverSpace();
  const [isWarningModal, setWarningModal] = useState(false);

  useEffect(() => {
    if (receiverId) {
      setReceiverId(receiverId);
      receiverSocket();
    }
  }, [receiverId]);

  useEffect(() => {
    if (receiverFiles) {
      setReceiverData(receiverFiles.result);
    }
  }, [receiverFiles]);

  useEffect(() => {
    console.log(receiverError);
    if (receiverError) {
      setWarningModal(true);
    }
  }, [receiverError]);

  const receiverSocket = () => {
    if (receiverId) {
      const socket = io(
        process.env.REACT_APP_RECEIVER_NOTIFICATION_URL as string,
        {
          extraHeaders: {
            receiverId: receiverId as string,
            sessionid: receiverId,
          },
        }
      );
      if (socket) {
        console.log('******STARTING RECEIVER SOCKET********');
        socket
          .off('receiverAccessPermission')
          .on('receiverAccessPermission', (data: ReceiverSocketMessage) => {
            console.log('RECEIVER DATA ====', data);
            if (data.message === 'receiverDelete') {
              setWarningModal(true);
            }
          });
        socket.on('disconnect', (reason) => {
          socket.disconnect();
          // if (reason === 'io server disconnect')
          //   setOpenModal('duplicateTabModal');
          console.log('Disconnected from the server ==', reason);
        });
      }
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <ReceiverSidebar ownerName={receiverData?.OwnerName || ''} />
        <div className={styles.cover}>
          <div className={styles.top}>
            <h1>
              {receiverData?.spaceName ||
                // `${receiverData?.OwnerName || ''}'s Space`
                t('labels.spaceOfOwner', {name: receiverData?.OwnerName})
              }
            </h1>
          </div>
          <FileListLayout
            isReceiver={true}
            spaceFiles={receiverData?.getFile || []}
          />
        </div>
      </div>
      <Modal isOpen={isWarningModal} onClose={() => {}}>
        <p>{t(getErrorMessage(receiverError?.subStatusCode || '2005'))}</p>
      </Modal>
    </>
  );
};

export default ReceiverSpace;
