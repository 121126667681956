import React from 'react';
import { Button, Input } from '@willeder/component-library';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import useAlert from 'store/useAlert';
import useChangePassword from 'hooks/profile/useChangePassword';
import useModalStore from 'store/useModalStore';
import { passwordRegex } from 'utils/constant';
import { getErrorMessage, getSuccessMessage } from 'utils/helper';
import './ChangePasswordModal.scss';

const ChangePasswordModal = () => {
  const { t } = useTranslation();
  const setAlert = useAlert((state) => state.setAlert);
  const { onChangePassword, isLoading } = useChangePassword();
  const { closeModal } = useModalStore((state) => state);

  const validationSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required(t(getErrorMessage('1008')))
      .matches(passwordRegex, t(getErrorMessage('1003'))),
    newPassword: yup
      .string()
      .required(t(getErrorMessage('1008')))
      .matches(passwordRegex, t(getErrorMessage('1003'))),
    confirmPassword: yup
      .string()
      .required(t(getErrorMessage('1008')))
      .oneOf([yup.ref('newPassword')], t(getErrorMessage('1009'))),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleChangePassword = (changePassword: ChangePasswordPaylod) => {
    onChangePassword({
      currentPassword: changePassword.currentPassword,
      newPassword: changePassword.newPassword,
    })
      .then((response) => {
        if (response.success) {
          setAlert(t(getSuccessMessage('3001')), 'success');
          closeModal();
        }
      })
      .catch((err: ResponseError) =>
        setAlert(t(getErrorMessage(err?.subStatusCode)))
      );
  };

  return (
    <div className={'passwordModal'}>
      <h1 className="title">{t('common.changePassword')}</h1>
      <Input
        label={t('fields.currentPassword')}
        placeholder={t('placeholders.currentPassword')}
        inputProps={{
          ...register('currentPassword'),
          type: 'password',
        }}
        errorMessage={errors.currentPassword?.message}
      />
      <Input
        label={t('fields.newPassword')}
        placeholder={t('placeholders.newPassword')}
        inputProps={{
          ...register('newPassword'),
          type: 'password',
        }}
        errorMessage={errors.newPassword?.message}
      />
      <Input
        label={t('fields.confirmPassword')}
        placeholder={t('placeholders.confirmPassword')}
        inputProps={{
          ...register('confirmPassword'),
          type: 'password',
        }}
        errorMessage={errors.confirmPassword?.message}
      />
      <div className="buttonWrapper">
        <Button
          className="update"
          onClick={handleSubmit(handleChangePassword)}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {t('buttons.save')}
        </Button>
        <Button className="close" onClick={closeModal} disabled={isLoading}>
        {t('buttons.close')}
        </Button>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
