import React, { Component, InputHTMLAttributes } from 'react';
import './CheckBox.scss';

interface CheckBoxProps {
  label: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  variant?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onClick?:()=>void
}

class CheckBox extends Component<CheckBoxProps> {
  render() {
    const { label, checked, onChange, variant, inputProps, onClick } = this.props;

    return (
      <label className={`custom-checkbox ${variant}`}>
        <span onClick={onClick}>
        {label}
        </span>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange && onChange(!checked)}
          {...inputProps}
        />
        <span className="checkmark"></span>
      </label>
    );
  }
}

export default CheckBox;
