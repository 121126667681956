import React from 'react';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-message.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import styles from './Alert.module.scss';

interface AlertMessageProps {
  type: 'success' | 'error' | 'info';
  message: string;
  showBlock?: boolean;
  blockMessage?: string;
  blockTime?: string;
}

const AlertMessage: React.FC<AlertMessageProps> = ({
  type = 'error',
  message,
  showBlock = false,
  blockMessage = '',
  blockTime = '',
}) => {
  let iconComponent;

  switch (type) {
    case 'success':
      iconComponent = <SuccessIcon className={styles.icon} />;
      break;
    case 'error':
      iconComponent = <ErrorIcon className={styles.icon} />;
      break;
    case 'info':
      iconComponent = <InfoIcon className={styles.icon} />;
      break;
    default:
      iconComponent = <SuccessIcon className={styles.icon} />;
      break;
  }

  return (
    <>
      {!showBlock && (
        <div className={`${styles.alert} ${styles[type]}`}>
          {iconComponent}
          <span className={styles.message}>{message}</span>
        </div>
      )}
      {showBlock && (
        <div className={`${styles.block} ${styles[type]}`}>
          {iconComponent}
          <span className={styles.blockMessage}>{blockMessage}</span>
          <span className={styles.blockMessage}>{blockTime}</span>
        </div>
      )}
    </>
  );
};

export default AlertMessage;
