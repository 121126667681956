import React, { FC, useState, useMemo, useCallback } from 'react';
import UserStatusMolecule from 'components/molecules/UserDetails/UserDetails';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import useModalStore from 'store/useModalStore';
import { useCryptoLink } from 'hooks/encryption/useCryptoLink';
import { useTranslation } from 'react-i18next';
import useSpaceReceiver from 'hooks/space/useSpaceReceiver';
import Randomstring from 'randomstring';
import styles from './Sidebar.module.scss';
import useAlert from 'store/useAlert';
import { getErrorMessage, getSuccessMessage, isExpired } from 'utils/helper';
import userSpaceStore from 'store/userSpaceStore';
import useSpace from 'hooks/space/useSpace';

interface SidebarProps {
  receivers?: ReceiverData[];
  isReceiver?: boolean;
  showStatus?: boolean;
}

const Sidebar: FC<SidebarProps> = ({
  receivers = [],
  isReceiver = false,
  showStatus = false,
}) => {
  const [receiverId, setReceiverId] = useState('');
  const { setOpenModal, setModalProps, setConfirmModalProps, closeModal } =
    useModalStore();
  const { generateLink, generateSharedSpaceLink } = useCryptoLink();
  const {
    regenerateCryptoLink,
    onReceiverAccessChange,
    deleteReceiver,
    isLoading,
  } = useSpaceReceiver(receiverId);
  const setAlert = useAlert((state) => state.setAlert);
  const { t } = useTranslation();
  const { currentSpaceId } = userSpaceStore();
  const { getSpaceData } = useSpace(currentSpaceId);

  const memoizedReceivers = useMemo(() => receivers, [receivers]);

  const onEditReceiver = useCallback(
    (
      id: string,
      name: string,
      createdAt: string,
      expiredAt: string,
      sharedSpaceExpiredAt: string
    ) => {
      setOpenModal('addReceiver');
      setModalProps({
        isEdit: true,
        receiverId: id,
        receiverName: name,
        createdAt: createdAt,
        expiredAt: expiredAt,
        sharedSpaceExpiredAt: sharedSpaceExpiredAt,
      });
    },
    [setOpenModal, setModalProps]
  );

  const onOpenGeneratedLink = useCallback(
    (id: string, transferKey: string) => {
      setOpenModal('receiverCryptoLink');
      const link = generateLink(id, transferKey);
      setModalProps({ generatedCryptoLink: link });
    },
    [setOpenModal, setModalProps, generateLink]
  );

  const onCopyGeneratedLink = useCallback(
    (id: string, transferKey: string, status: string) => {
      const link = generateLink(id, transferKey);
      navigator.clipboard.writeText(link);

      const alertMessage =
        status === 'waiting'
          ? t('labels.copiedLinkWaitingTooltip')
          : t('success.linkCopied');

      setAlert(alertMessage, 'info');
    },
    [generateLink, setAlert]
  );

  const regenerateLink = useCallback(() => {
    regenerateCryptoLink({ encryptedSecretCode: Randomstring.generate() })
      .then((response) => {
        if (response.success) {
          setAlert(t(getSuccessMessage('default')), 'success');
          closeModal();
          const link = generateLink(response.receiverId, response.transferKey);
          setReceiverId('');
          getSpaceData();
          setOpenModal('receiverCryptoLink');
          setModalProps({ generatedCryptoLink: link, isRegenerated: true });
        }
      })
      .catch((error: ResponseError) =>
        setAlert(t(getErrorMessage(error.subStatusCode)))
      );
  }, [
    regenerateCryptoLink,
    setAlert,
    t,
    closeModal,
    setReceiverId,
    setOpenModal,
    setModalProps,
    generateLink,
    receiverId,
  ]);

  const onRegenerateLink = useCallback(
    (id: string) => {
      setReceiverId(id);
      setOpenModal('confirmModal', 'confirm');
      setConfirmModalProps({
        title: t('links.regenerateLink'),
        content: <p>{t('labels.regenerateLinkConfirmation')}</p>,
        cancel: {
          children: t('buttons.no'),
          onClick: () => closeModal(),
        },
        confirm: {
          children: t('buttons.yes'),
          onClick: regenerateLink,
        },
        isOpen: true,
      });
    },
    [
      setReceiverId,
      setOpenModal,
      setConfirmModalProps,
      t,
      closeModal,
      regenerateLink,
    ]
  );

  const onDeactivateReceiver = useCallback(() => {
    if (!isLoading) {
      onReceiverAccessChange()
        .then((response) => {
          if (response.success) {
            setAlert(t(getSuccessMessage('default')), 'success');
            closeModal();
            setReceiverId('');
          }
        })
        .catch((error: ResponseError) =>
          setAlert(t(getErrorMessage(error.subStatusCode)))
        );
    }
  }, [onReceiverAccessChange, setAlert, t, closeModal, setReceiverId]);

  const onChangeReceiverStatus = useCallback(
    (id: string, status: string) => {
      setReceiverId(id);
      setOpenModal('confirmModal', 'confirm');
      setConfirmModalProps({
        title:
          status === 'deactivated'
            ? t('labels.statusActivate')
            : t('labels.statusDeactivate'),
        content: (
          <p>
            {t('labels.deactivateReceiverConfirmation1', {
              status:
                status === 'deactivated'
                  ? t('labels.statusActivate')
                  : t('labels.statusDeactivate'),
            })}
          </p>
        ),
        cancel: {
          children: t('buttons.no'),
          onClick: closeModal,
        },
        confirm: {
          children: t('buttons.yes'),
          onClick: onDeactivateReceiver,
        },
        isOpen: true,
      });
    },
    [
      setReceiverId,
      setOpenModal,
      setConfirmModalProps,
      t,
      closeModal,
      onDeactivateReceiver,
    ]
  );

  const removeReceiver = useCallback(() => {
    if (!isLoading) {
      deleteReceiver()
        .then((response) => {
          if (response.success) {
            setAlert(t(getSuccessMessage('default')), 'success');
            closeModal();
            setReceiverId('');
          }
        })
        .catch((error: ResponseError) =>
          setAlert(t(getErrorMessage(error.subStatusCode)))
        );
    }
  }, [deleteReceiver, setAlert, t, closeModal, setReceiverId]);

  const onRemoveReceiver = useCallback(
    (id: string) => {
      setReceiverId(id);
      setOpenModal('confirmModal', 'confirm');
      setConfirmModalProps({
        title: t('links.removeReceiver'),
        content: <p>{t('labels.deleteReceiverConfirmation')}</p>,
        cancel: {
          children: t('buttons.no'),
          onClick: closeModal,
        },
        confirm: {
          children: t('buttons.yes'),
          onClick: removeReceiver,
        },
        isOpen: true,
      });
    },
    [
      setReceiverId,
      setOpenModal,
      setConfirmModalProps,
      t,
      closeModal,
      removeReceiver,
    ]
  );

  const onCopySharedSpaceLink = (receiverId: string) => {
    // const link = generateSharedSpaceLink(currentSpaceId);
    const link = `${process.env.REACT_APP_FRONT_URL}/shared-space/${currentSpaceId}`;
    navigator.clipboard.writeText(link);
    setAlert(t('labels.copiedLinkActiveTooltip'), 'info');
  };

  return (
    <div className={styles.sidebar}>
      {isReceiver ? (
        <>
          <div className={styles.allUsers}>{t('labels.allUsers')}</div>
          <p className={styles.userName}>username</p>
        </>
      ) : (
        <>
          <div className={styles.allUsers}>
            {t('labels.allUsers')}{' '}
            {/* {<ShareIcon onClick={onCopySharedSpaceLink} />} */}
          </div>
          <div className={styles.userDetailWrapper}>
            {memoizedReceivers.map((receiver) => (
              <UserStatusMolecule
                key={receiver._id}
                userName={receiver.receiverName}
                status={
                  receiver.status === 'waiting'
                    ? isExpired(receiver.expiredAt)
                      ? 'expired'
                      : receiver.status
                    : receiver.status
                }
                isGuest={receiver.isGuest}
                readStatus={receiver.readStatus}
                onEditReceiver={() =>
                  onEditReceiver(
                    receiver.receiverId,
                    receiver.receiverName,
                    receiver.createdAt,
                    receiver.expiredAt,
                    receiver.sharedSpaceExpiredAt
                  )
                }
                onCopyGeneratedLink={() => {
                  if (receiver.status === 'active') {
                    onCopySharedSpaceLink(receiver.receiverId);
                  } else
                    onCopyGeneratedLink(
                      receiver.receiverId,
                      receiver.transferKey,
                      receiver.status
                    );
                }}
                onOpenGeneratedLink={() =>
                  onOpenGeneratedLink(receiver.receiverId, receiver.transferKey)
                }
                onRegenerateLink={() => onRegenerateLink(receiver.receiverId)}
                onDeactivateReceiver={() =>
                  onChangeReceiverStatus(receiver.receiverId, receiver.status)
                }
                onRemoveReceiver={() => onRemoveReceiver(receiver.receiverId)}
              />
            ))}
          </div>
          {!memoizedReceivers.length && <p>{t('labels.noReceivers')}</p>}
        </>
      )}
    </div>
  );
};

export default Sidebar;
