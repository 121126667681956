import React, { FC } from 'react';
import { Button, Input } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useModalStore from 'store/useModalStore';
import useAlert from 'store/useAlert';
import useSpaceReceiver from 'hooks/space/useSpaceReceiver';
import {
  convertTimestamp,
  getErrorMessage,
  getSuccessMessage,
} from 'utils/helper';
import Randomstring from 'randomstring';
import './AddReceiverModal.scss';
import { AddReceiverModalProps } from 'types/modals';
import { useCryptoLink } from 'hooks/encryption/useCryptoLink';

const AddReceiverModal: FC<AddReceiverModalProps> = ({
  isEdit,
  receiverId,
  receiverName,
  createdAt,
  expiredAt,
  sharedSpaceExpiredAt,
}) => {
  const { t } = useTranslation();
  const setAlert = useAlert((state) => state.setAlert);
  const { setOpenModal, setModalProps, closeModal } = useModalStore();
  const { addReceiver, renameReceiver, isLoading } =
    useSpaceReceiver(receiverId);
  const { generateLink } = useCryptoLink();

  const validationSchema = yup.object().shape({
    receiverName: yup
      .string()
      .required(t(getErrorMessage('3007')))
      .min(1, t('errors.minCharacters', { min: '1' }))
      .max(20, t('errors.maxCharacters', { max: '20' })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      receiverName: receiverName || '',
    },
  });

  const onAddReceiver = (receiverData: AddReceiverPayload) => {
    addReceiver({
      receiverName: receiverData.receiverName,
      encryptedSecretCode: Randomstring.generate(),
    })
      .then((response) => {
        closeModal();
        setOpenModal('receiverCryptoLink');
        const link = generateLink(response.receiverId, response.transferKey);
        setModalProps({ generatedCryptoLink: link, isNewReceiver: true });
        setAlert(t(getSuccessMessage('default')), 'success');
      })
      .catch((error: ResponseError) =>
        setAlert(t(getErrorMessage(error.subStatusCode)))
      );
  };

  const onEditReceiver = (receiverData: AddReceiverPayload) => {
    renameReceiver(receiverData)
      .then((response) => {
        setAlert(t(getSuccessMessage('default')), 'success');
        closeModal();
      })
      .catch((error: ResponseError) =>
        setAlert(t(getErrorMessage(error.subStatusCode)))
      );
  };

  return (
    <div className={'receiverModal'}>
      <h1 className="title">
        {isEdit ? t('titles.editReceiver') : t('titles.addReceiver')}
      </h1>
      {isEdit && (
        <div className="createdAtWrapper">
          <p className="text">
            {t('labels.createdAt')} {convertTimestamp(createdAt || '', true)}
          </p>
          <p className="text">
            {t('labels.expiredAt')} {convertTimestamp(expiredAt || '', true)}
          </p>
          {sharedSpaceExpiredAt && (
            <p className="text">
              {'Shared Space Link Expire at:'}{' '}
              {convertTimestamp(sharedSpaceExpiredAt || '', true)}
            </p>
          )}
        </div>
      )}
      <Input
        placeholder={t('placeholders.enterReceiversName')}
        inputProps={{ ...register('receiverName') }}
        errorMessage={errors.receiverName?.message}
      />
      <div className="buttonWrapper">
        <Button
          className="update"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit(isEdit ? onEditReceiver : onAddReceiver)}
        >
          {isEdit ? t('buttons.save') : t('buttons.add')}
        </Button>
        <Button className="close" onClick={closeModal} disabled={isLoading}>
          {t('buttons.close')}
        </Button>
      </div>
    </div>
  );
};

export default AddReceiverModal;
