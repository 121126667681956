import { create } from 'zustand';
import { ModalStoreState } from './@types/modalStore';

const useModalStore = create<ModalStoreState>((set) => ({
  openModal: null,
  modalType: 'default',
  modalProps: undefined,
  confirmModalProps: undefined,

  setOpenModal: (modalName, modalType = 'default') => {
    set({ openModal: modalName, modalType: modalType });
  },

  closeModal: () =>
    set({
      openModal: null,
      modalType: 'default',
      modalProps: undefined,
      confirmModalProps: undefined,
    }),

  setModalProps: (modalProps) => set({ modalProps }),

  setConfirmModalProps: (confirmModalProps) => set({ confirmModalProps }),
}));

export default useModalStore;
