import { create } from 'zustand';

interface SpaceState {
  userSpacesData: DashboardSpaceList[] | null;
  currentSpaceId: string;
  fileId: string;
  spaceFilesToUpload: null | FilesUploadPayload;
  spaceFilesToDownload: null | FilesUploadPayload;
  spaceFilesToDelete: null | FilesUploadPayload;
  isTempSpace: boolean;
  receiverId: string;
  bulkId: string;
  recentBulkId: string;
  receiverData: UserReceiverResponse | null;
  spaceSizeData: StorageApiResponse['result'][0] | null;
  spaceNotificationId: string;
  spaceReceivers: ReceiverData[] | null;
  setUserSpaceList: (spacesData: DashboardSpaceList[]) => void;
  setCurrentSpaceId: (spaceId: string) => void;
  setCurrentFileId: (fileId: string) => void;
  setFilesToUpload: (data: FilesUploadPayload | null) => void;
  setRecentBulkId: (id: string) => void;
  setFilesToDownload: (data: FilesUploadPayload | null) => void;
  setFilesToDelete: (data: FilesUploadPayload | null) => void;
  setTempSpace: (isTemp: boolean) => void;
  setReceiverId: (receiverId: string) => void;
  setBulkId: (bulkId: string) => void;
  setReceiverData: (data: UserReceiverResponse) => void;
  setSpaceSizeData: (data: StorageApiResponse['result'][0]) => void;
  setSpaceNotificationId: (id: string) => void;
  setSpaceReceivers: (data: ReceiverData[]) => void;
}

const userSpaceStore = create<SpaceState>((set) => ({
  userSpacesData: null,
  currentSpaceId: '',
  fileId: '',
  recentBulkId: '',
  spaceFilesToUpload: null,
  spaceFilesToDownload: null,
  spaceFilesToDelete: null,
  isTempSpace: false,
  receiverId: '',
  bulkId: '',
  receiverData: null,
  spaceSizeData: null,
  spaceNotificationId: '',
  spaceReceivers: null,

  setUserSpaceList: (userSpacesData) => {
    set({ userSpacesData });
  },

  setCurrentSpaceId: (spaceId) => set({ currentSpaceId: spaceId }),

  setCurrentFileId: (fileId) => set({ fileId: fileId }),

  setFilesToUpload: (data) => set({ spaceFilesToUpload: data }),

  setRecentBulkId: (data) => set({ recentBulkId: data }),

  setFilesToDownload: (data) => set({ spaceFilesToDownload: data }),

  setFilesToDelete: (data) => set({ spaceFilesToDelete: data }),

  setTempSpace: (isTemp) => set({ isTempSpace: isTemp }),

  setReceiverId: (receiverId) => set({ receiverId: receiverId }),

  setBulkId: (bulkId) => set({ bulkId: bulkId }),

  setReceiverData: (data) => set({ receiverData: data }),

  setSpaceSizeData: (data) => set({ spaceSizeData: data }),

  setSpaceNotificationId: (data) => set({ spaceNotificationId: data }),

  setSpaceReceivers: (data) => set({ spaceReceivers: data }),
}));

export default userSpaceStore;
