import useNotificationStore from 'store/notificationStore';
import useModalStore from 'store/useModalStore';
import { getCookies } from 'utils/cookies';
import { initializeSocketConnection } from 'utils/socket';

const useNotificationSocket = () => {
  const accessToken = getCookies('accessToken');

  const { setOpenModal } = useModalStore();
  const { setNotificationStatus, socket, setSocket, setReloadReceiverStatus } =
    useNotificationStore();

  const connectToNotificationSocket = () => {
    if (!!accessToken) {
      const socket = initializeSocketConnection();
      if (socket) {
        try {
          setSocket(socket);
          if (!socket.active) socket?.connect();
          console.log('******STARTING SOCKET********');
          socket
            .off('receiverAccessPermission')
            .on(
              'receiverAccessPermission',
              (data: NotificationSocketResponse) => {
                console.log('=== receiverId ===', data);
                if (data.message === 'notification')
                  setNotificationStatus(!!data.receiver);
                else if (data.message === 'receiverStatus') {
                  setReloadReceiverStatus(true);
                }
              }
            );

          socket.on('disconnect', (reason: string) => {
            socket.disconnect();
            if (reason === 'io server disconnect')
              setOpenModal('duplicateTabModal');
            console.log('Disconnected from the server ==', reason);
          });
        } catch (e) {
          if (socket.active) socket.disconnect();
          console.log(e);
        }
      }
    }
  };

  const onDisconnectSocket = () => {
    socket?.off('receiverAccessPermission');
    if (socket && socket?.active) {
      console.log('CLOSED SOCKET!!');
      socket?.disconnect();
    }
  };

  return {
    connectToNotificationSocket,
    onDisconnectSocket,
  };
};

export default useNotificationSocket;
