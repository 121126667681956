import useSWR, { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import userSpaceStore from 'store/userSpaceStore';

const useSpaceReceiver = (receiverId?: string) => {
  const { post, put, del, get } = useAPI();
  const spaceId = userSpaceStore((state) => state.currentSpaceId);
  const spaceNotificationId = userSpaceStore(
    (state) => state.spaceNotificationId
  );

  const {
    data: receiverData,
    error,
    isLoading,
  } = useSWR(receiverId ? API_ROUTES.getReceiverById(receiverId) : null, get, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  const addReceiverInSpace = async (
    url: string,
    { arg }: { arg: AddReceiverPayload }
  ) => {
    const response: AddReceiver = await post(url, arg);
    mutate(API_ROUTES.getReceiversBySpace(spaceId));
    mutate(API_ROUTES.getSpaceById(spaceId));
    return response;
  };

  const {
    trigger: addReceiver,
    isMutating: adding,
    error: addError,
  } = useSWRMutation(
    spaceId ? API_ROUTES.addReceiver(spaceId) : '',
    addReceiverInSpace
  );

  const sendUpdateRequest = async (
    url: string,
    { arg }: { arg: Partial<AddReceiverPayload> }
  ) => {
    const response: ApiResponse = await put(url, arg);
    mutate(API_ROUTES.getSpaceById(spaceId));
    mutate(API_ROUTES.getReceiversBySpace(spaceId));
    return response;
  };

  const {
    trigger: renameReceiver,
    isMutating: updating,
    error: updateError,
  } = useSWRMutation(
    receiverId ? API_ROUTES.renameReceiver(receiverId) : null,
    sendUpdateRequest
  );

  const sendChangeAccessRequest = async (url: string) => {
    const response: ApiResponse = await put(url, {});
    mutate(API_ROUTES.getReceiversBySpace(spaceId));
    return response;
  };

  const {
    trigger: onReceiverAccessChange,
    isMutating: changing,
    error: changeError,
  } = useSWRMutation(
    receiverId ? API_ROUTES.changeReceiverAccess(receiverId) : null,
    sendChangeAccessRequest
  );

  const sendUpdateStatusRequest = async (
    url: string,
    {
      arg,
    }: {
      arg: { status: 'active' | 'deactivated'; spaceNotificationId: string };
    }
  ) => {
    if (arg.spaceNotificationId) {
      const response: ApiResponse = await put(
        API_ROUTES.changeReceiverAccessFromNotification(
          arg.spaceNotificationId
        ),
        arg
      );
      mutate(API_ROUTES.getReceiversBySpace(spaceId));
      return response;
    }
    return null;
  };

  const {
    trigger: onNotificationStatusUpdate,
    isMutating: statusUpdating,
    error: statusError,
  } = useSWRMutation('changeReceiverAccess', sendUpdateStatusRequest);

  const sendRegenerateLinkRequest = async (
    url: string,
    { arg }: { arg: Partial<AddReceiverPayload> }
  ) => {
    const response: RegenerateLinkResponse = await put(url, arg);
    return response;
  };

  const {
    trigger: regenerateCryptoLink,
    isMutating: generating,
    error: generateError,
  } = useSWRMutation(
    receiverId ? API_ROUTES.regenerateReceiverLink(receiverId) : null,
    sendRegenerateLinkRequest
  );

  const sendDeleteRequest = async (url: string) => {
    const response: ApiResponse = await del(url);
    mutate(API_ROUTES.getSpaceById(spaceId));
    mutate(API_ROUTES.getReceiversBySpace(spaceId));
    return response;
  };

  const {
    trigger: deleteReceiver,
    isMutating: deleting,
    error: deleteError,
  } = useSWRMutation(
    receiverId ? API_ROUTES.deleteReceiverFromSpace(receiverId) : null,
    sendDeleteRequest
  );

  return {
    receiverData,
    addReceiver,
    renameReceiver,
    onReceiverAccessChange,
    regenerateCryptoLink,
    deleteReceiver,
    onNotificationStatusUpdate,
    isLoading:
      isLoading ||
      adding ||
      updating ||
      changing ||
      generating ||
      deleting ||
      statusUpdating,
    error:
      error ||
      addError ||
      updateError ||
      changeError ||
      generateError ||
      deleteError ||
      statusError,
  };
};

export default useSpaceReceiver;
