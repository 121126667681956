import React, { FC } from 'react';
import styles from './Sidebar.module.scss';
import { useTranslation } from 'react-i18next';

interface SidebarProps {
  ownerName: string;
}

const ReceiverSidebar: FC<SidebarProps> = ({ ownerName = '' }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.sidebar}>
      <div className={styles.allUsers}>{t('labels.owner')}</div>
      <p className={styles.userName}>{ownerName}</p>
      {/* <p className={styles.userName}>{t('labels.spaceOfOwner', {name: ownerName})}</p> */}
    </div>
  );
};

export default ReceiverSidebar;
