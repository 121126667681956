import React from 'react';
import './ColorOption.scss';

interface ColorOptionProps {
  id: string;
  color: string;
  field?: any;
}

const ColorOption: React.FC<ColorOptionProps> = ({ id, color, field }) => {
  const isChecked = field.value === color;

  return (
    <div className={`colorOption ${color}`}>
      <input
        type="radio"
        name="color"
        id={id}
        value={color}
        {...field}
        onChange={() => field.onChange(color)}
        checked={isChecked}
      />
      <label htmlFor={id}>
        <span className={`checkmark ${color}`}></span>
      </label>
    </div>
  );
};

export default ColorOption;
