import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthStore from 'store/authStore';
import { getCookies } from 'utils/cookies';

interface AuthRoutesProps {
  children: JSX.Element;
}

const AuthRoutes = ({ children }: AuthRoutesProps) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const accessToken = getCookies('accessToken');

  return isAuthenticated && accessToken ? <Navigate to="/" /> : <>{children}</>;
};

export default AuthRoutes;
