import React from 'react';
import { Button } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import useModalStore from 'store/useModalStore';
import styles from '../ConfirmModal/ConfirmModal.module.scss';

const DuplicateTabModal = () => {
  const { t } = useTranslation();
  const { closeModal } = useModalStore((state) => state);

  const handleRefresh = () => {
    window.location.reload();
    closeModal();
  };

  return (
    <div className={styles.confirmModal}>
      <div className={styles.description}>{t('errors.duplicateTabText')}</div>
      <Button className="close" onClick={handleRefresh}>
        {t('buttons.refreshPage')}
      </Button>
    </div>
  );
};

export default DuplicateTabModal;
