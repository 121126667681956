import React from 'react';
import './Switch.scss';

interface SwitchProps {
  value: 'ja' | 'en';
  onToggle: () => void;
}

const Switch: React.FC<SwitchProps> = ({ value, onToggle }) => {
  return (
    <label className="label">
      <span>EN</span>
      <input type="checkbox" checked={value === 'ja'} onChange={onToggle} />
      <span>JA</span>
    </label>
  );
};

export default Switch;
