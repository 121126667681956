import useSWRMutation from 'swr/mutation';
import API_ROUTES from 'services/apiRoutes';
import { useAPI } from 'hooks/useAPI';

const useProfileSecretCode = () => {
  const { put } = useAPI();

  const sendUpdateRequest = async (
    url: string,
    { arg }: { arg: { secretAuth: string } }
  ) => {
    const response: ApiResponse = await put(url, arg);
    return response;
  };

  const {
    trigger: changeSecretCode,
    isMutating: isLoading,
    error,
  } = useSWRMutation(API_ROUTES.changeSecretCode, sendUpdateRequest);

  return {
    changeSecretCode,
    isLoading,
    error,
  };
};

export default useProfileSecretCode;
