import React from 'react';
import { Button, Input } from '@willeder/component-library';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import useResetPassword from 'hooks/auth/useResetPassword';
import useAlert from 'store/useAlert';
import useAuthStore from 'store/authStore';
import useModalStore from 'store/useModalStore';
import { getErrorMessage, getSuccessMessage } from 'utils/helper';
import { passwordRegex } from 'utils/constant';
import Logo from '../../assets/icons/logo.svg';
import styles from './ResetPassword.module.scss';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const otpId = useAuthStore((state) => state.otpId);
  const setAlert = useAlert((state) => state.setAlert);
  const { onResetPassword, isLoading } = useResetPassword();
  const { setOpenModal, setModalProps, closeModal } = useModalStore();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(t(getErrorMessage('3001')))
      .matches(passwordRegex, t(getErrorMessage('3002'))),
    confirmPassword: yup
      .string()
      .required(t(getErrorMessage('3001')))
      .oneOf([yup.ref('password')], t(getErrorMessage('3006'))),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleResetPassword = (passwordData: ResetPasswordProps) => {
    if (otpId) {
      onResetPassword({ otpId: otpId, password: passwordData.password })
        .then((response) => {
          if (response.success) {
            setOpenModal('successModal');
            setModalProps({
              message: t(getSuccessMessage('8005')),
              onContinue: () => {
                closeModal();
                navigate('/login');
              },
            });
          }
        })
        .catch((error: ResponseError) =>
          setAlert(t(getErrorMessage(error.subStatusCode)))
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <img src={Logo} alt="Logo" />
        <h1>{t('titles.resetPassword')}</h1>
      </div>
      <Input
        label={t('fields.password')}
        placeholder={t('placeholders.password')}
        inputProps={{
          ...register('password'),
          type: 'password',
        }}
        errorMessage={errors.password?.message}
      />
      <Input
        label={t('fields.confirmPassword')}
        placeholder={t('placeholders.confirmPassword')}
        inputProps={{
          ...register('confirmPassword'),
          type: 'password',
        }}
        errorMessage={errors.confirmPassword?.message}
      />
      <Button
        onClick={handleSubmit(handleResetPassword)}
        disabled={isLoading}
        isLoading={isLoading}
        className="test"
      >
        {t('titles.resetPassword')}
      </Button>
    </div>
  );
};

export default ResetPassword;
