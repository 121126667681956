import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@willeder/component-library';
import styles from './CustomMessagePage.module.scss';

interface CustomMessagePageProps {
  message: string;
  buttonText: string;
  redirectTo: string;
  icon: React.ReactNode
}

const CustomMessagePage:React.FC<CustomMessagePageProps> = ({ message, buttonText, redirectTo, icon }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.messageBox}>
        {icon}
        <p>{message}</p>
        <Button className="test" onClick={() => navigate(redirectTo)}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default CustomMessagePage;
