import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@willeder/component-library';
import useModalStore from 'store/useModalStore';
import styles from './NewUserFileUploadModal.module.scss';

const NewUserFileUploadModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { closeModal } = useModalStore();

  const onGotoUpload = () => {
    navigate(`/sender-space/temp-space`);
    closeModal();
  };

  return (
    <div className={styles.confirmModal}>
      <h1 className={styles.title}>{t('labels.skipUploadingProcess')}</h1>
      <p>{t('labels.confirmSkipUploadingProcess')}</p>
      <div className={styles.buttonWrapper}>
        <Button className="update" onClick={onGotoUpload}>
          {t('buttons.gotoUpload')}
        </Button>
        <Button className="close" onClick={closeModal}>
          {t('buttons.close')}
        </Button>
      </div>
    </div>
  );
};

export default NewUserFileUploadModal;
