import React from 'react';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Input } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import usePaymentCard from 'hooks/payment/usePaymentCard';
import useModalStore from 'store/useModalStore';
import useAlert from 'store/useAlert';
import useAuthStore from 'store/authStore';
import { getErrorMessage } from 'utils/helper';
import './AddPaymentCardModal.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

const StripeCard = () => {
  const { t } = useTranslation();
  const { closeModal } = useModalStore();
  const { setPaymentMethodId } = useAuthStore();
  const { addPaymentCard, isLoading } = usePaymentCard();
  const setAlert = useAlert((state) => state.setAlert);

  const stripe = useStripe();
  const elements = useElements();

  const handleCardAdd = async () => {
    try {
      const cardElement: any = await elements?.getElement(CardElement);
      await stripe
        ?.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then((res: any) => {
          setPaymentMethodId(res.paymentMethod.id);
          addPaymentCard(res.paymentMethod.id)
            .then((response) => {
              setAlert('success', 'success');
            })
            .catch((error) => {
              setAlert(t(getErrorMessage(error?.subStatusCode)));
            });
        });

      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={'editModal'}>
      <h1 className="title">{t('buttons.addCard')}</h1>
      <Input
        placeholder={t('placeholders.accountHolderName')}
        label={t('fields.accountHolderName')}
        className=""
      />
      <div className="willeder-inputWrapper">
        <label>{t('fields.cardNumber')}</label>
        <CardElement className="card-input" />
      </div>
      <div className="buttonWrapper">
        <Button
          className="update"
          onClick={handleCardAdd}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {t('buttons.add')}
        </Button>
        <Button className="close" onClick={closeModal} disabled={isLoading}>
          {t('buttons.close')}
        </Button>
      </div>
    </div>
  );
};

const AddPaymentCardsModal = () => {
  return (
    <Elements stripe={stripePromise}>
      <StripeCard />
    </Elements>
  );
};

export default AddPaymentCardsModal;
