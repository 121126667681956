import React, { FC } from 'react';
import { Button } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'components/molecules/ProgressBar/ProgressBar';
import useFileOperations from 'hooks/spaceFiles/useFileOperations';
import userSpaceStore from 'store/userSpaceStore';
import { prepareAccordionFileData } from 'utils/helper';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as DownloadDeactive } from '../../../assets/icons/download_disable.svg';
import styles from './FileListLayout.module.scss';
import AccordionTable from 'components/molecules/AccordionTable';
import ConfirmModal from 'components/molecules/ConfirmModal';

interface FileListProps {
  isReceiver?: boolean;
  spaceFiles: FileData[];
}

const FileListLayout: FC<FileListProps> = ({
  isReceiver = false,
  spaceFiles,
}) => {
  const { t, i18n } = useTranslation();
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);
  const accordionItems = prepareAccordionFileData(
    spaceFiles,
    i18n?.language === 'ja' ? 'ja' : 'en'
  );

  const {
    onDownloadAllFiles,
    onDeleteAllFiles,
    isLoading,
    findParentItem,
    onUpdateSelectedItems,
    selectedItems,
    isDeleteModal,
    setDeleteModal,
  } = useFileOperations(accordionItems);
  const { spaceSizeData } = userSpaceStore((state) => state);

  const handleSelectAllChange = (isChecked: boolean) => {
    setSelectAllChecked(isChecked);
    let selectedItems: string[] = [];
    accordionItems.forEach((item) => {
      if (item?.status === 'labels.off') return;
      let updatedSelectedItems = [...selectedItems];
      if (isChecked) {
        updatedSelectedItems.push(item.id);
        if (!!item?.children?.length) {
          item.children?.forEach((child: { id: string }) => {
            if (!updatedSelectedItems.includes(child.id)) {
              updatedSelectedItems.push(child.id);
            }
          });
        } else {
          const parentItem = findParentItem(accordionItems, item.id);
          if (
            parentItem &&
            parentItem.children?.every((child) =>
              updatedSelectedItems.includes(child.id)
            )
          ) {
            updatedSelectedItems.push(parentItem.id);
          }
        }
      } else {
        updatedSelectedItems = updatedSelectedItems.filter(
          (id) => id !== item.id
        );
        if (!!item?.children?.length) {
          item.children?.forEach((child: { id: string }) => {
            updatedSelectedItems = updatedSelectedItems.filter(
              (id) => id !== child.id
            );
          });
        } else {
          const parentItem = findParentItem(accordionItems, item.id);
          if (parentItem) {
            updatedSelectedItems = updatedSelectedItems.filter(
              (id) => id !== parentItem.id
            );
          }
        }
      }
      selectedItems = [...updatedSelectedItems];
    });

    onUpdateSelectedItems(selectedItems);
  };

  // TODO: cleanup unnecessary button animation uk
  return (
    <div className={styles.wrapper}>
      <div className={styles.dragWrapper}>
        <div className={styles.dragDropBox}>
          <div className={styles.buttonWrapper}>
            {!isReceiver && (
              <ProgressBar
                totalStorage={
                  (spaceSizeData?.totalStorageSize || 0) * 1024 * 1024
                }
                fileUploadSize={spaceSizeData?.totalFileSize || 0}
              />
            )}
            <div className={styles.animateBtnWrapper}>
              <div
                className={`${styles.borderGlow} ${selectedItems.length ? styles.withItems : ''}`}
              ></div>
              <Button
                className={`download ss-btn ${selectedItems.length ? styles.animate : ''} ${selectedItems.length ? styles.glowing : ''}`}
                icon={
                  selectedItems.length ? <DownloadIcon /> : <DownloadDeactive />
                }
                isLoading={isLoading}
                iconPosition="right"
                disabled={!selectedItems.length || isLoading}
                onClick={() => onDownloadAllFiles()}
              >
                {t('buttons.download')}
              </Button>
            </div>
            {!isReceiver && (
              <Button
                className="cancelButton"
                onClick={() => setDeleteModal(true)}
                disabled={!selectedItems.length || isLoading}
              >
                {t('buttons.delete')}
              </Button>
            )}
          </div>
          <AccordionTable
            showReceiverAccordion={isReceiver}
            handleSelectAllChange={handleSelectAllChange}
            selectAllChecked={selectAllChecked}
            items={accordionItems}
            header={[
              t('labels.fileName'),
              t('labels.size'),
              t('labels.status'),
              t('labels.activateAt'),
              '',
            ]}
          />
        </div>
      </div>
      <ConfirmModal
        title={t('labels.deleteFiles')}
        content={<p>{t('labels.deleteFileConfirmation')}</p>}
        cancel={{
          children: t('buttons.no'),
          onClick: () => setDeleteModal(false),
        }}
        confirm={{
          children: t('buttons.yes'),
          onClick: () => onDeleteAllFiles(),
        }}
        isOpen={isDeleteModal}
      />
    </div>
  );
};

export default FileListLayout;
