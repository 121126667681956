import axios, { AxiosResponse } from 'axios';
import useSWRMutation from 'swr/mutation';
import API_ROUTES from 'services/apiRoutes';

const useSecretAuth = () => {
  const validateSecretCode = async (
    url: string,
    { arg }: { arg: SecretAuthPayload }
  ) => {
    const response: AxiosResponse = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}${url}`,
      { secretAuth: arg.secretAuth },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${arg.accessToken}`,
        },
      }
    );
    return response.data;
  };

  const {
    trigger: validateLoginSecretCode,
    isMutating,
    error,
  } = useSWRMutation(API_ROUTES.verifySecretCode, validateSecretCode);

  const {
    trigger: validateRegisterSecretCode,
    isMutating: validating,
    error: validationError,
  } = useSWRMutation(API_ROUTES.secureAuth, validateSecretCode);

  const {
    trigger: resetSecretAuthCode,
    isMutating: resetting,
    error: resetError,
  } = useSWRMutation(API_ROUTES.resetSecureAuth, validateSecretCode);

  return {
    validateLoginSecretCode,
    validateRegisterSecretCode,
    resetSecretAuthCode,
    isLoading: isMutating || validating || resetting,
    error: error || validationError || resetError,
  };
};

export default useSecretAuth;
