import useSWR from 'swr';
import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import useSWRMutation from 'swr/mutation';

const useProfile = () => {
  const { get, put } = useAPI();

  const {
    data: userData,
    error,
    isLoading,
    mutate,
  } = useSWR<UserProfileResponse>(API_ROUTES.getUserProfile, get, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnMount: false,
  });

  const sendUpdateRequest = async (
    url: string,
    { arg }: { arg: Partial<RegisterPayload> }
  ) => {
    const response: ApiResponse = await put(url, arg);
    mutate();
    return response;
  };

  const {
    trigger: updateProfile,
    isMutating: updating,
    error: updateError,
  } = useSWRMutation(API_ROUTES.updateProfile, sendUpdateRequest);

  const sendDeleteRequest = async (url: string) => {
    const response: ApiResponse = await put(url, {});
    return response;
  };

  const {
    trigger: deleteProfile,
    isMutating: deleting,
    error: deleteError,
  } = useSWRMutation(API_ROUTES.deleteProfile, sendDeleteRequest);

  return {
    userData,
    getProfileData: mutate,
    updateProfile,
    deleteProfile,
    isLoading: isLoading || updating || deleting,
    error: error || updateError || deleteError,
  };
};

export default useProfile;
