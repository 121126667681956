import useSWR, { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import useAuthStore from 'store/authStore';

const usePaymentCard = () => {
  const { post, put, del, get } = useAPI();
  const paymentMethodId = useAuthStore((state) => state.paymentMethodId);

  const {
    data: paymentCards,
    error,
    isLoading,
    // mutate,
  } = useSWR<PaymentCardsResponse>(API_ROUTES.getPaymentCards, get, {
    // revalidateOnFocus: false,
    // revalidateIfStale: false,
  });

  const addNewCard = async (paymentMethodId: string) => {
    const response: SpaceCreateResponse = await post(
      API_ROUTES.addPaymentCard(paymentMethodId),
      {}
    );
    mutate(API_ROUTES.getPaymentCards);
    return response;
  };

  const {
    trigger: addPaymentCard,
    isMutating: creating,
    error: createError,
  } = useSWRMutation(
    paymentMethodId ? API_ROUTES.addPaymentCard(paymentMethodId) : null,
    addNewCard
  );

  const sendUpdateRequest = async (paymentMethodId: string) => {
    const response: ApiResponse = await put(
      API_ROUTES.setDefaultCard(paymentMethodId),
      {}
    );
    mutate(API_ROUTES.getPaymentCards);
    return response;
  };

  const {
    trigger: setDefaultCard,
    isMutating: updating,
    error: updateError,
  } = useSWRMutation(
    paymentMethodId ? API_ROUTES.setDefaultCard(paymentMethodId) : null,
    sendUpdateRequest
  );

  const sendDeleteRequest = async (paymentMethodId: string) => {
    const response: ApiResponse = await del(
      API_ROUTES.deletePaymentCard(paymentMethodId)
    );
    mutate(API_ROUTES.getPaymentCards);
    return response;
  };

  const {
    trigger: deleteCard,
    isMutating: deleting,
    error: deleteError,
  } = useSWRMutation(
    paymentMethodId ? API_ROUTES.deletePaymentCard(paymentMethodId) : null,
    sendDeleteRequest
  );

  return {
    paymentCards,
    addPaymentCard: addNewCard,
    setDefaultCard: sendUpdateRequest,
    deleteCard: sendDeleteRequest,
    isLoading: isLoading || creating || updating || deleting,
    error: error || createError || updateError || deleteError,
  };
};

export default usePaymentCard;
