import { MutableRefObject } from 'react';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface FileStoreState {
  isCancelled: boolean;
  selectedFiles: string[];
  fileList: AccordionItem[];
  progress: MutableRefObject<number | undefined> | null;
  progressPercentage: number;
  isLoading: boolean;
  mode: 'download' | 'delete';
  currentFileIndex: number;
  currentStatusIndex: number;
  setSelectedFiles: (files: string[]) => void;
  setProgressDetails: (
    fileList: AccordionItem[],
    progress: MutableRefObject<number | undefined>,
    mode: 'download' | 'delete'
  ) => void;
  setLoading: (status: boolean) => void;
  setProgressPercentage: (per: number) => void;
  updateFileStatus: (index: number, status: number) => void;
  setIsCancelled: (isCancelled: boolean) => void;
  clearStoreData: () => void;
}

const useFileStore = create<FileStoreState>()(
  persist(
    (set) => ({
      selectedFiles: [],
      fileList: [],
      progress: null,
      mode: 'download',
      currentFileIndex: 0,
      currentStatusIndex: 0,
      isLoading: false,
      progressPercentage: 0,
      isCancelled: false,

      setSelectedFiles: (files) => {
        set({ selectedFiles: files });
      },

      setProgressDetails: (fileList, progress, mode) =>
        set({ fileList, progress, mode }),

      setLoading: (status) => set({ isLoading: status }),

      setProgressPercentage: (per) => set({ progressPercentage: per }),

      updateFileStatus: (index, number) => {
        set({ currentFileIndex: index, currentStatusIndex: number });
      },

      setIsCancelled: (isCancelled) => set({ isCancelled }),

      clearStoreData: () =>
        set({
          // selectedFiles: [],
          fileList: [],
          progress: null,
          mode: 'download',
          currentFileIndex: 0,
          currentStatusIndex: 0,
          isLoading: false,
          progressPercentage: 0,
          isCancelled: false,
        }),
    }),
    {
      name: 'file-operation-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useFileStore;
