import instance from 'utils/axios';
import { AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios';

export const useAPI = () => {
  const makeRequest = async <T>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    data?: unknown
  ): Promise<T> => {
    const config: AxiosRequestConfig = { method, url };
    if (data) {
      config.data = data;
    }
    try {
      const response: AxiosResponse<T> = await instance.request(config);
      return response.data;
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        return Promise.reject(err.response?.data);
      } else return Promise.reject(err);
    }
  };

  return {
    get: <T>(url: string) => makeRequest<T>('GET', url),
    post: <T>(url: string, data: unknown) => makeRequest<T>('POST', url, data),
    put: <T>(url: string, data?: unknown) => makeRequest<T>('PUT', url, data),
    del: <T>(url: string) => makeRequest<T>('DELETE', url),
  };
};
