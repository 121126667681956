import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@willeder/component-library';
import Modal from 'components/atoms/Modal';
import { AxiosError } from 'axios';
import ConfirmModal from 'components/molecules/ConfirmModal/ConfirmModal';
import GenerateCodeModal from 'components/molecules/GenerateCodeModal/GenerateCode';
import { useSecretCode } from 'hooks/encryption/useSecretCode';
import useSecretAuth from 'hooks/auth/useSecretAuth';
import useAuthStore from 'store/authStore';
import useAlert from 'store/useAlert';
import { getErrorMessage, getSuccessMessage } from 'utils/helper';
import { setCookies } from 'utils/cookies';
import Logo from '../../assets/icons/logo.svg';
import styles from './SecretCode.module.scss';
import useGuestToUser from 'hooks/receiver/useGuestToUser';

const SecretCode = () => {
  const navigate = useNavigate();
  const { userId, secretKey, secretSalt, loginToken, setIsAuthenticated } =
    useAuthStore((state) => state);
  const setAlert = useAlert((state) => state.setAlert);
  const { getSecretAuthCode, getNewSecretAuthCode } = useSecretCode();
  const { validateLoginSecretCode, resetSecretAuthCode, isLoading } =
    useSecretAuth();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecretModalOpen, setSecretCodeModalOpen] = useState(false);
  const [secretCode, setSecretCode] = useState('');
  const isGuest = localStorage.getItem('isGuest');
  const { onConvertGuestToUser } = useGuestToUser();

  const onVerifySecretCode = async () => {
    const { secretAuth, encryptedSecretCode } = getSecretAuthCode(
      secretCode,
      userId,
      secretKey,
      secretSalt
    );
    validateLoginSecretCode({
      secretAuth: secretAuth,
      accessToken: loginToken || '',
    })
      .then((response: SecretAuthResponse) => {
        if (response.success) {
          const { accessToken, refreshToken } = response.result;
          localStorage.setItem('encryptedSecretCode', encryptedSecretCode);
          setCookies('accessToken', accessToken);
          setCookies('refreshToken', refreshToken, 30);

          if (isGuest === 'true') onConvertGuestToUser();

          setIsAuthenticated(true);
          navigate('/');
        }
      })
      .catch((error: AxiosError) => {
        const data: ResponseError | any = error.response?.data;
        setAlert(t(getErrorMessage(data?.subStatusCode || 'default')));
      });
  };

  const onResetSecretCode = () => {
    const { secretAuth, encryptedSecretCode, secretCode } =
      getNewSecretAuthCode(userId, secretKey, secretSalt);
    if (secretAuth && loginToken) {
      resetSecretAuthCode({
        secretAuth: secretAuth,
        accessToken: loginToken,
      })
        .then((response) => {
          if (response.success) {
            localStorage.setItem('encryptedSecretCode', encryptedSecretCode);
            setAlert(t(getSuccessMessage('8004')), 'success');
            setIsModalOpen(false);
            setSecretCode(secretCode);
            setSecretCodeModalOpen(true);
          }
        })
        .catch((error) => {
          const { data } = error.response;
          setAlert(t(getErrorMessage(data.subStatusCode)));
        });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <img src={Logo} alt="Logo" />
        <h1>{t('common.secretCode')}</h1>
      </div>
      <Input
        placeholder={t('placeholders.enterSecretCode')}
        inputProps={{
          type: 'text',
          value: secretCode,
          onChange: (e) => setSecretCode(e.target.value),
        }}
      />
      <Button
        className="test"
        onClick={onVerifySecretCode}
        isLoading={isLoading}
        disabled={isLoading}
      >
        {t('buttons.submit')}
      </Button>
      <div className={styles.reset}>
        <p className={styles.link} onClick={() => setIsModalOpen(true)}>
          {t('labels.forgotSecretCode')}
        </p>
        <ConfirmModal
          title={t('titles.resetAccount')}
          content={<p>{t('labels.resetAccountConfirmation')}</p>}
          cancel={{
            children: t('buttons.no'),
            onClick: () => setIsModalOpen(false),
          }}
          confirm={{
            children: t('buttons.yes'),
            onClick: () => onResetSecretCode(),
          }}
          isOpen={isModalOpen}
        />
        <Modal
          isOpen={isSecretModalOpen}
          onClose={() => setSecretCodeModalOpen(false)}
          showCloseButton={false}
        >
          <GenerateCodeModal
            secretCode={secretCode}
            onClose={() => setSecretCodeModalOpen(false)}
          />
        </Modal>
      </div>
    </div>
  );
};

export default SecretCode;
