import { Socket } from 'socket.io-client';
import { create } from 'zustand';

interface NotificationState {
  isNotification: boolean;
  receiverId: string;
  notificationData: NotificationResponse | null;
  isReloadReceiver: boolean;
  socket: Socket | null;
  setReceiverId: (receiverId: string) => void;
  setNotificationData: (data: NotificationResponse) => void;
  setNotificationStatus: (status: boolean) => void;
  setReloadReceiverStatus: (status: boolean) => void;
  setSocket: (socket: Socket) => void;
}

const useNotificationStore = create<NotificationState>((set) => ({
  receiverId: '',
  variant: 'error',
  isNotification: false,
  notificationData: null,
  isReloadReceiver: false,
  socket: null,

  setReceiverId: (receiverId) => {
    set({ receiverId: receiverId, isNotification: true });
  },

  setNotificationStatus: (status) => set({ isNotification: status }),

  setNotificationData: (data) => set({ notificationData: data }),

  setReloadReceiverStatus: (status: boolean) =>
    set({ isReloadReceiver: status }),

  setSocket: (socket) => set({ socket }),
}));

export default useNotificationStore;
