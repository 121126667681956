import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from '@willeder/component-library';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Randomstring from 'randomstring';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/atoms/CheckBox';
import ColorOption from 'components/atoms/ColorOption/ColorOption';
import useSpace from 'hooks/space/useSpace';
import useSpaceFiles from 'hooks/spaceFiles/useSpaceFiles';
import useModalStore from 'store/useModalStore';
import useAlert from 'store/useAlert';
import userSpaceStore from 'store/userSpaceStore';
import { colorOptions, timeOptions } from 'utils/constant';
import { getErrorMessage } from 'utils/helper';
import styles from './AddSpace.module.scss';

const AddSpaceModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setAlert = useAlert((state) => state.setAlert);
  const { closeModal, setOpenModal, setModalProps } = useModalStore();
  const spaceId = userSpaceStore((state) => state.currentSpaceId);
  const isTempSpace = userSpaceStore((state) => state.isTempSpace);
  const {
    spaceFilesToUpload,
    setFilesToUpload,
    spaceSizeData,
    setRecentBulkId,
  } = userSpaceStore((state) => state);
  const { createSpace, isLoading } = useSpace(spaceId);
  const { addFilesToSpace } = useSpaceFiles();

  const validationSchema = yup.object().shape({
    receiverName: yup
      .string()
      .required(t(getErrorMessage('3007')))
      .min(1, t('errors.minCharacters', { min: '1' }))
      .max(20, t('errors.maxCharacters', { max: '20' })),
    spaceColor: yup.string().oneOf(colorOptions.map((color) => color.id)),
    spaceName: yup.string(),
    deactivateAt: yup.string().required(t(getErrorMessage('3009'))),
    accessPermission: yup.boolean().required(t(getErrorMessage('3009'))),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      deactivateAt: '7d',
      accessPermission: false,
      spaceName: '',
      spaceColor: 'orange',
    },
  });

  const onCreateSpace = (spaceData: any) => {
    // if (!spaceData.spaceName) {
    //   spaceData.spaceName = spaceData.receiverName;
    // }
    createSpace({ ...spaceData, encryptedSecretCode: Randomstring.generate() })
      .then((data: SpaceCreateResponse) => {
        if (isTempSpace && spaceFilesToUpload) {
          addFilesToSpace(spaceFilesToUpload)
            .then((response) => {
              if (response.success) {
                setRecentBulkId(spaceFilesToUpload.files[0].bulkId);
                navigate(`/sender-space/${data.data.spaceId}`);
                setFilesToUpload(null);
              }
            })
            .catch((e) => setAlert(t(getErrorMessage(e?.subStatusCode))));
        }
        closeModal();
        setOpenModal('receiverCryptoLink');
        setModalProps({
          generatedCryptoLink: data.data.link,
          isNewReceiver: true,
          isNewSpace: true,
        });
      })
      .catch((error: ResponseError) => {
        setAlert(t(getErrorMessage(error.subStatusCode)));
      });
  };

  return (
    <div className={styles.addSpace}>
      <h1 className={styles.title}>{t('titles.addSpace')}</h1>
      <p className={styles.para}>{t('labels.createSpaceDescription')}</p>
      <Input
        placeholder={t('placeholders.enterReceiversName')}
        inputProps={{ ...register('receiverName') }}
        errorMessage={errors.receiverName?.message}
      />
      <div className="willeder-inputWrapper">
        <div className={styles.color}>
          <h2 className={styles.subTitle}>{t('fields.color')}</h2>
          <Controller
            name="spaceColor"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <div className={styles.spaceColors}>
                {colorOptions.map((option) => (
                  <ColorOption
                    key={option.id}
                    {...option}
                    field={{ ...field }}
                  />
                ))}
              </div>
            )}
          />
        </div>
        <p className="willeder-input-error">{errors.spaceColor?.message}</p>
      </div>
      <details>
        <summary>{t('labels.advanceSetting')}</summary>
        <div className={styles.settingContent}>
          <Input
            label={t('fields.spaceName')}
            placeholder={t('placeholders.enterSpaceName')}
            inputProps={{ ...register('spaceName') }}
            errorMessage={errors.spaceName?.message}
          />
          <div className="willeder-inputWrapper">
            <div className={styles.inputRange}>
              <p>{t('fields.onlineAt')}</p>
              {spaceSizeData?.planType === 'free' ? (
                <p>7日(day)</p>
              ) : (
                <select {...register('deactivateAt')}>
                  {timeOptions?.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
            <p className="willeder-input-error">
              {errors.deactivateAt?.message}
            </p>
          </div>
          <div className="willeder-inputWrapper">
            <CheckBox
              label={t('labels.initialAccessPermission')}
              variant="space"
              inputProps={{ ...register('accessPermission') }}
            />
            <p className="willeder-input-error">
              {errors.accessPermission?.message}
            </p>
          </div>
        </div>
      </details>
      <div className={styles.buttonWrapper}>
        <Button
          className="update"
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit(onCreateSpace)}
        >
          {/* {t('buttons.add')} */}
          {t('buttons.createSubmit')}
        </Button>
        {!isTempSpace && (
          <Button
            className="close"
            disabled={isLoading}
            onClick={() => closeModal()}
          >
            {t('buttons.close')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default AddSpaceModal;
