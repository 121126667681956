import axios, { isAxiosError } from 'axios';

export const refreshAccessToken = async (payload: RefreshTokenPayload) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/user/auth/refresh`,
      payload
    );
    return Promise.resolve(response.data);
  } catch (err: unknown) {
    if (isAxiosError(err)) {
      return Promise.reject(err.response?.data);
    } else return Promise.reject(err);
  }
};
