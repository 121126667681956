import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from 'pages/Login';
import Dashboard from 'pages/Dashboard';
import Signup from 'pages/Signup';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import SecretCode from 'pages/SecretCode';
import OTPVerification from 'pages/OTPVerification';
import CustomMessagePage from 'pages/CustomMessagePage';
import SenderSpace from 'pages/SenderSpace';
import ReceiverSpace from 'pages/ReceiverSpace';
import ProtectedRoutes from './ProtectedRoutes';
import AuthRoutes from './AuthRoutes';
import ProtectedReceiverSpace from 'pages/ProtectedReceiverSpace';
import { ReactComponent as SuccessIcon } from '../assets/icons/success.svg';
import { ReactComponent as CancelIcon } from '../assets/icons/cancel-message.svg';
import { useTranslation } from 'react-i18next';

export const Router = () => {
  const { t } = useTranslation();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoutes>
            <Dashboard />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/login"
        element={
          <AuthRoutes>
            <Login />
          </AuthRoutes>
        }
      />
      <Route
        path="/signup"
        element={
          <AuthRoutes>
            <Signup />
          </AuthRoutes>
        }
      />
      <Route
        path="/signup/otp-code"
        element={
          <AuthRoutes>
            <OTPVerification screen={'Signup'} />
          </AuthRoutes>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <AuthRoutes>
            <ForgotPassword />
          </AuthRoutes>
        }
      />
      <Route
        path="/reset-password"
        element={
          <AuthRoutes>
            <ResetPassword />
          </AuthRoutes>
        }
      />
      <Route
        path="/secret-code"
        element={
          <AuthRoutes>
            <SecretCode />
          </AuthRoutes>
        }
      />
      <Route
        path="/forgot-password/otp-code"
        element={
          <AuthRoutes>
            <OTPVerification screen={'ForgotPassword'} />
          </AuthRoutes>
        }
      />
      <Route
        path="/sender-space/:spaceId"
        element={
          <ProtectedRoutes>
            <SenderSpace />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/receiver-space/:receiverId"
        element={
          <ProtectedRoutes>
            <ProtectedReceiverSpace />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/receiver-space/:receiverId/:sharedKey"
        element={<ReceiverSpace />}
      />
      {/* <Route
        path="/shared-space/:spaceId/:sharedKey"
        element={<ReceiverSpace />}
      /> */}
      <Route path="/shared-space/:spaceId" element={<ReceiverSpace />} />
      <Route
        path="/shared-files/:sharedFilesLinkId/:sharedKey"
        element={<ReceiverSpace />}
      />

      <Route
        path="/success"
        element={
          <CustomMessagePage
            message={t('common.payment.success.message')}
            buttonText={t('common.payment.success.btnTitle')}
            redirectTo="/"
            icon={<SuccessIcon />}
          />
        }
      />
      <Route
        path="/cancel"
        element={
          <CustomMessagePage
            message={t('common.payment.fail.message')}
            buttonText={t('common.payment.success.btnTitle')}
            redirectTo="/"
            icon={<CancelIcon />}
          />
        }
      />

      <Route path="*" element={<h1>Page not found</h1>} />
    </Routes>
  );
};
