import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from '@willeder/component-library';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import useProfile from 'hooks/profile/useProfile';
import useModalStore from 'store/useModalStore';
import useAlert from 'store/useAlert';
import useAuthStore from 'store/authStore';
import { removeCookies } from 'utils/cookies';
import { getErrorMessage, getSuccessMessage } from 'utils/helper';
import { emailRegex } from 'utils/constant';
import './AccountInfoModal.scss';
import { mutate } from 'swr';

const AccountInfoModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setAlert = useAlert((state) => state.setAlert);
  const { locale, setIsAuthenticated } = useAuthStore((state) => state);
  const { userData, isLoading, updateProfile, deleteProfile } = useProfile();
  const { closeModal, setOpenModal, setConfirmModalProps } = useModalStore(
    (state) => state
  );

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(t(getErrorMessage('3007')))
      .max(20, t('errors.maxCharacters', { max: '20' })),
    email: yup
      .string()
      .email(t(getErrorMessage('3004')))
      .required(t(getErrorMessage('3003')))
      .matches(emailRegex, t(getErrorMessage('3004')))
      .max(255, t('errors.maxCharacters', { max: '255' })),
    locale: yup.string().oneOf(['en', 'ja']),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: userData?.result.name || '',
      email: userData?.result.email || '',
      locale: locale,
    },
  });

  useEffect(() => {
    if (userData?.success) {
      setValue('name', userData.result.name);
      setValue('email', userData.result.email);
    }
  }, [setValue, userData]);

  const handleEditAccount = (editData: Partial<RegisterPayload>) => {
    updateProfile(editData)
      .then((response) => {
        if (response.success) {
          setAlert(t(getSuccessMessage('3003')), 'success');
        }
      })
      .catch((err: ResponseError) =>
        setAlert(t(getErrorMessage(err?.subStatusCode)))
      );
  };

  const onDeleteUserAccount = () => {
    deleteProfile()
      .then((response) => {
        if (response.success) {
          setAlert(t(getSuccessMessage('8006')), 'success');
          closeModal();
          setIsAuthenticated(false);
          removeCookies('accessToken');
          removeCookies('refreshToken');
          removeCookies('loginToken');
          localStorage.removeItem('encryptedSecretCode');
          localStorage.removeItem('auth-storage');
          mutate(() => true, undefined, false);
          navigate('/login');
        }
      })
      .catch((err: ResponseError) =>
        setAlert(t(getErrorMessage(err?.subStatusCode)))
      );
  };

  const onDeleteAccount = () => {
    setOpenModal('confirmModal', 'confirm');

    setConfirmModalProps({
      title: t('titles.deleteAccount'),
      content: t('labels.deleteAccount'),
      cancel: {
        children: t('buttons.no'),
        onClick: () => closeModal(),
      },
      confirm: {
        children: t('buttons.yes'),
        onClick: () => onDeleteUserAccount(),
      },
      isOpen: true,
      isLoading: isLoading,
    });
  };

  return (
    <div className={'accountModal'}>
      <h1 className="title">{t('titles.accountInformation')}</h1>
      <Input
        placeholder={t('placeholders.name')}
        inputProps={{ ...register('name') }}
        errorMessage={errors.name?.message}
      />
      <Input
        placeholder={t('placeholders.email')}
        inputProps={{
          ...register('email'),
          type: 'email',
        }}
        errorMessage={errors.email?.message}
      />
      <div className="buttonWrapper">
        <Button
          className="update"
          onClick={handleSubmit(handleEditAccount)}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {t('buttons.save')}
        </Button>
        <Button
          className="delete"
          onClick={onDeleteAccount}
          disabled={isLoading}
        >
          {t('buttons.deleteAccount')}
        </Button>
        <Button className="close" onClick={closeModal}>
          {t('buttons.close')}
        </Button>
      </div>
    </div>
  );
};

export default AccountInfoModal;
