import React from 'react';

interface TooltipContentProps {
  title?: React.ReactNode;
  display: boolean;
  className?: string | { container: string; content: string };
  styles?: { container?: React.CSSProperties; content?: React.CSSProperties };
  rect?: { top: number; left: number; width: number; height: number; bottom: number } | null;
}

const TooltipContent: React.FC<TooltipContentProps> = ({
  title = null,
  display=false,
  className = 'react-tooltip',
  styles = {},
  rect = null,
}) => {
  if (!title) {
    return null;
  }

  const wrapperAttributes: React.HTMLProps<HTMLSpanElement> = { style: {} };
  const contentAttributes: React.HTMLProps<HTMLSpanElement> = {};

  if (typeof className === 'string') {
    wrapperAttributes.className = className;
    contentAttributes.className = `${className}__content`;
  } else {
    wrapperAttributes.className = className?.container;
    contentAttributes.className = className?.content;
  }

  if (styles.container) {
    wrapperAttributes.style = styles.container;
  }
  if (styles.content) {
    contentAttributes.style = styles.content;
  }

  if (rect) {
    wrapperAttributes.style = {
      ...wrapperAttributes.style,
      left: `${rect.left + (rect.width / 2)}px`,
      top: `${rect.bottom}px`,
      transform: 'translate(-50%, 0)',
    };
  }

  return (
    <span {...wrapperAttributes}>
      {display && (
      <span {...contentAttributes}>{title}</span>
      )}
    </span>
  );
};

export default TooltipContent;
