import useSWR, { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import { getCookies } from 'utils/cookies';

const useNotification = (offset: number = 0) => {
  const { put, get } = useAPI();
  const accessToken = getCookies('accessToken');

  const {
    data: notificationData,
    error,
    isLoading,
    mutate: getNotificationData,
  } = useSWR<NotificationResponse>(
    accessToken ? API_ROUTES.getNotificationStatus : '',
    get,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: false,
      errorRetryCount: 3,
    }
  );

  const {
    data: notificationsList,
    error: notificationsError,
    isLoading: notificationsLoading,
    mutate: getNotificationsList,
  } = useSWR<NotificationListResponse>(
    API_ROUTES.getSpaceAccessNotifications(offset),
    get,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: false,
      errorRetryCount: 3,
    }
  );

  const sendUpdateRequest = async (url: string) => {
    const response: ApiResponse = await put(url, {});
    return response;
  };

  const {
    trigger: updateNotificationStatus,
    isMutating: updating,
    error: updateError,
  } = useSWRMutation(API_ROUTES.notificationStatusUpdate, sendUpdateRequest);

  const sendDeleteRequest = async (url: string) => {
    const response: ApiResponse = await put(url, {});
    mutate(API_ROUTES.getAllSpaces);
    return response;
  };

  const {
    trigger: clearNotification,
    isMutating: deleting,
    error: deleteError,
  } = useSWRMutation(API_ROUTES.clearAllNotifications, sendDeleteRequest);

  return {
    notificationData,
    notificationsList,
    getNotificationData,
    getNotificationsList,
    updateNotificationStatus,
    clearNotification,
    isLoading: isLoading || notificationsLoading || updating || deleting,
    error: error || notificationsError || updateError || deleteError,
  };
};

export default useNotification;
