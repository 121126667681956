import { io } from 'socket.io-client';
import { getCookies } from './cookies';

const URL = process.env.REACT_APP_NOTIFICATION_URL || '';

export const initializeSocketConnection = () => {
  const refreshToken = getCookies('refreshToken');
  if (refreshToken) {
    return io(URL, {
      autoConnect: false,
      extraHeaders: {
        refreshtoken: refreshToken || '',
        sessionid: refreshToken,
      },
    });
  }
  console.log('Refresh token is not available.');
  return null;
};
