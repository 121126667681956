import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DashboardCard from 'components/molecules/DashboardCard';
import useUserSpaces from 'hooks/space/useUserSpaces';
import useSpace from 'hooks/space/useSpace';
import useReceiverSpace from 'hooks/receiver/useReceiverSpace';
import { useSecretCode } from 'hooks/encryption/useSecretCode';
import useSpaceFiles from 'hooks/spaceFiles/useSpaceFiles';
import useAuthStore from 'store/authStore';
import useModalStore from 'store/useModalStore';
import userSpaceStore from 'store/userSpaceStore';
import useAlert from 'store/useAlert';
import {
  checkReadStatus,
  getErrorMessage,
  getSuccessMessage,
} from 'utils/helper';
import styles from './Dashboard.module.scss';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import DashboardImg from '../../assets/img/dashboard.png';
import API_ROUTES from 'services/apiRoutes';

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [spaceList, setSpaceList] = useState<DashboardSpaceList[]>();

  const { isNewUser, setNewUser, secretKey } = useAuthStore((state) => state);
  const { setOpenModal, setModalProps, closeModal, setConfirmModalProps } =
    useModalStore();
  const { userSpaces, isLoading, getSpaceList } = useUserSpaces(0);
  const { onDeleteReceiverSpace } = useReceiverSpace();
  const { getSecretCode } = useSecretCode();
  const { setUserSpaceList, setCurrentSpaceId, setSpaceSizeData } =
    userSpaceStore((state) => state);
  const { sendDeleteRequest } = useSpace();
  const setAlert = useAlert((state) => state.setAlert);
  const { spaceSizeData, getStorageInfo, error } = useSpaceFiles();

  useEffect(() => {
    if (isNewUser) {
      setOpenModal('secretCode');
      const secretCode = getSecretCode(secretKey);
      setModalProps({
        secretCode: secretCode,
        onClose: () => {
          closeModal();
          setOpenModal('newUserFileUpload');
        },
      });
      setNewUser(false);
    }
  }, [isNewUser]);

  useEffect(() => {
    if (userSpaces && userSpaces.success) {
      const docs = userSpaces.data;
      setSpaceList(docs);
      setUserSpaceList(userSpaces.data);
    }
  }, [userSpaces]);

  const onEditSpace = (spaceId: string) => {
    setCurrentSpaceId(spaceId);
    setOpenModal('editSpace');
  };

  const onDeleteSpace = (spaceId: string) => {
    sendDeleteRequest(API_ROUTES.deleteSpaceById(spaceId))
      .then((data) => {
        if (data.success) {
          setCurrentSpaceId('');
          setAlert(t(getSuccessMessage('default')), 'success');
          closeModal();
        }
      })
      .catch((error: ResponseError) => {
        setAlert(t(getErrorMessage(error.subStatusCode)));
      });
  };

  const onDeleteSpaceClick = (spaceId: string) => {
    setCurrentSpaceId(spaceId);
    setOpenModal('confirmModal', 'confirm');
    setConfirmModalProps({
      title: t('titles.deleteSpace'),
      content: t('labels.deleteSpaceConfirmation'),
      cancel: {
        children: t('buttons.no'),
        onClick: () => closeModal(),
      },
      confirm: {
        children: t('buttons.yes'),
        onClick: () => onDeleteSpace(spaceId),
      },
      isOpen: true,
    });
  };

  const onDeleteReceiverSpaceClick = (receiverId: string) => {
    setOpenModal('confirmModal', 'confirm');
    setConfirmModalProps({
      title: t('titles.deleteSpace'),
      content: t('labels.deleteSpaceConfirmation'),
      cancel: {
        children: t('buttons.no'),
        onClick: () => closeModal(),
      },
      confirm: {
        children: t('buttons.yes'),
        onClick: () => {
          onDeleteReceiverSpace(receiverId).then(() => {
            getSpaceList();
            setAlert(t(getSuccessMessage('default')), 'success');
            closeModal();
          });
        },
      },
      isOpen: true,
    });
  };

  const onSpaceDetailClick = (spaceId: string) => {
    setCurrentSpaceId(spaceId);
    navigate(`/sender-space/${spaceId}`);
  };

  useEffect(() => {
    if (spaceSizeData?.success) {
      setSpaceSizeData(spaceSizeData.result[0]);
    } else getStorageInfo();
  }, [spaceSizeData]);

  useEffect(() => {
    if (error?.subStatusCode) setAlert(t(getErrorMessage(error.subStatusCode)));
  }, [error]);

  const items = [
    { label: 'labels.CreateSharedSpaceForEachUser', color: '' },
    { label: 'labels.UploadFilesSharedSpace', color: '' },
    {
      label: 'labels.SendTheEncryptedLink',
      sublabel1: 'labels.whenTheSharerConnectsTo',
      sublabel2: 'labels.e2eeFileSharingBeginsWhen',
      color: '',
    },
    { label: 'labels.IfYouWantToShareAgain', color: 'green' },
  ];

  // const renderTextWithIcon = (text: string, color: string) => {
  //   const parts = text.split(/\(([^)]+)\)/);
  //   return (
  //     <>
  //       {parts.map((part, index) =>
  //         index % 2 === 0 ? (
  //           <span key={index}>{part}</span>
  //         ) : part.trim().length === 0 ? (
  //           <span
  //             className={`${styles.renderTextWithIconCopyIconWrapper} ${color ? styles.greenIcon : ''} `}
  //             key={index}
  //           >
  //             &#40;
  //             <CopyIcon />
  //             &#41;
  //           </span>
  //         ) : (
  //           <span key={index}>{` (${part})`}</span>
  //         )
  //       )}
  //     </>
  //   );
  // };

  return (
    <div className={styles.dashBoardWrapper}>
      <div className={styles.dashBoard}>
        {spaceList?.map((space) =>
          space.type === 'space' ? (
            <DashboardCard
              key={space._id}
              title={
                space.spaceName ||
                space.alternativeReceiverName ||
                t('labels.noSharer')
              }
              readCaption={t(checkReadStatus(space))}
              colorVariant={space.spaceColor}
              onCardClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onSpaceDetailClick(space.spaceId || '');
              }}
              onEditClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onEditSpace(space.spaceId || '');
              }}
              onDeleteClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onDeleteSpaceClick(space.spaceId || '');
              }}
            />
          ) : (
            <DashboardCard
              key={space._id}
              title={space?.receiverName || t('labels.noSharer')}
              readCaption={''}
              colorVariant={space.spaceColor}
              onCardClick={(e) => {
                e.stopPropagation();
                navigate(`/receiver-space/${space.receiverId}`);
              }}
              isReceiverCard={true}
              onDeleteClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onDeleteReceiverSpaceClick(space?.receiverId || '');
              }}
              readStatus={space.readStatus}
            />
          )
        )}
        {isLoading ? (
          <div className={styles.textCenter}>Loading...</div>
        ) : (
          !spaceList?.length && <div>{t('labels.noSpace')}</div>
        )}
      </div>
      <div className={styles.boxContent}>
        {/* <h1>{t('labels.e2eeFileSharing')}</h1>
        <ol type="1" className={styles.orderList}>
          {items.map((item, index) => {
            return (
              <li key={index}>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  {renderTextWithIcon(t(item.label), item?.color)}
                </p>
                {item.sublabel1 && (
                  <p>
                    {t(item.sublabel1)}{' '}
                    <span style={{ color: '#33adff' }}>
                      {t(item.sublabel2)}
                    </span>
                  </p>
                )}
              </li>
            );
          })}
        </ol>
        <span className={styles.image}>
          <img src={SpaceImage} alt="" />
        </span> */}
        <img src={DashboardImg} alt="dashboard-img" />
      </div>
    </div>
  );
};

export default Dashboard;
