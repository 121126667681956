import useSWR, { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import userSpaceStore from 'store/userSpaceStore';

const useSpaceFiles = () => {
  const { post, put, del, get } = useAPI();
  const spaceId = userSpaceStore((state) => state.currentSpaceId);
  const fileId = userSpaceStore((state) => state.fileId);

  const {
    data: spaceFiles,
    error,
    isLoading,
    mutate: getSpaceFiles,
  } = useSWR<SpaceFileResponse>(
    spaceId ? API_ROUTES.getFilesBySpaceId(spaceId) : null,
    get,
    {
      revalidateOnFocus: false,
      // revalidateIfStale: false,
    }
  );

  const {
    data: spaceSizeData,
    error: spaceSizeError,
    isLoading: spacesSizeLoading,
    mutate: getStorageInfo,
  } = useSWR<StorageApiResponse>(API_ROUTES.getTotalStorageSpace, get, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnMount: false,
  });

  const addFilesInSpace = async (
    url: string,
    { arg }: { arg: FilesUploadPayload }
  ) => {
    const response: ApiResponse = await post(url, arg);
    getSpaceFiles();
    mutate(API_ROUTES.getReceiversBySpace(spaceId));

    return response;
  };

  const {
    trigger: addFilesToSpace,
    isMutating: creating,
    error: createError,
  } = useSWRMutation(
    spaceId ? API_ROUTES.spaceFileUpload(spaceId) : null,
    addFilesInSpace
  );

  const sendUpdateRequest = async (url: string) => {
    const response: ApiResponse = await put(url, {});
    getSpaceFiles();
    return response;
  };

  const {
    trigger: updateFileStatus,
    isMutating: updating,
    error: updateError,
  } = useSWRMutation(
    fileId ? API_ROUTES.updateFileStatus(fileId) : null,
    sendUpdateRequest
  );

  const sendDeleteRequest = async (url: string) => {
    const response: ApiResponse = await del(url);
    getSpaceFiles();
    return response;
  };

  const {
    trigger: deleteFiles,
    isMutating: deleting,
    error: deleteError,
  } = useSWRMutation(
    fileId ? API_ROUTES.deleteFileByFileId(fileId) : null,
    sendDeleteRequest
  );

  return {
    getSpaceFiles,
    spaceFiles,
    spaceSizeData,
    getStorageInfo,
    addFilesToSpace,
    updateFileStatus,
    deleteFiles,
    isLoading: isLoading || spaceSizeError || creating || updating || deleting,
    error:
      error || spacesSizeLoading || createError || updateError || deleteError,
  };
};

export default useSpaceFiles;
