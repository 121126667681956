import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@willeder/component-library';
import { SuccessModalProps } from 'types/modals';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';
import styles from './SuccessModal.module.scss';

const SuccessModal: FC<SuccessModalProps> = ({ message, onContinue }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.successBox}>
      <SuccessIcon />
      <h2>{t('titles.success')}</h2>
      <p>{message}</p>
      <Button className="test" onClick={onContinue}>
        {t('buttons.continue')}
      </Button>
    </div>
  );
};

export default SuccessModal;
