import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGoogleLogin } from '@react-oauth/google';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@willeder/component-library';
import Switch from 'components/atoms/Switch';
import CheckBox from 'components/atoms/CheckBox';
import useGoogleAuth from 'hooks/auth/useGoogleAuth';
import useRegister from 'hooks/auth/useRegister';
import useAuthStore from 'store/authStore';
import useAlert from 'store/useAlert';
import useModalStore from 'store/useModalStore';
import { getErrorMessage, getSuccessMessage } from 'utils/helper';
import { emailRegex, passwordRegex } from 'utils/constant';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google.svg';
import Logo from '../../assets/icons/logo.svg';
import styles from './Signup.module.scss';

const Signup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const setUserInfo = useAuthStore((state) => state.setUserInfo);
  const locale = useAuthStore((state) => state.locale);
  const setLocale = useAuthStore((state) => state.setLocale);
  const { setOpenModal, setModalProps } = useModalStore();
  const setAlert = useAlert((state) => state.setAlert);

  const { onLoginWithGoogle, isLoading: googleAuthLoading } = useGoogleAuth();
  const { sendRegisterOTP, isLoading } = useRegister();

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(t(getErrorMessage('3007')))
      .min(3, t('errors.minCharacters', { min: '3' }))
      .max(20, t('errors.maxCharacters', { max: '20' })),
    email: yup
      .string()
      .email(t(getErrorMessage('3004')))
      .required(t(getErrorMessage('3003')))
      .matches(emailRegex, t(getErrorMessage('3004')))
      .max(225, t('errors.maxCharacters', { max: '225' })),
    password: yup
      .string()
      .required(t(getErrorMessage('3001')))
      .matches(passwordRegex, t(getErrorMessage('3002'))),
    confirmPassword: yup
      .string()
      .required(t(getErrorMessage('3001')))
      .oneOf([yup.ref('password')], t(getErrorMessage('3006'))),
    terms: yup
      .boolean()
      .test('is-true', t(getErrorMessage('3008')), (value) => value === true)
      .required(t(getErrorMessage('3008'))),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      onLoginWithGoogle({ token: codeResponse.code, locale })
        .then((data) => {
          if (data.success) {
            const responseData = data.result;
            if (!responseData.isNewUser) navigate('/secret-code');
            else {
              setOpenModal('successModal');
              setModalProps({
                message: t(getSuccessMessage('8003')),
                onContinue: () => navigate('/'),
              });
            }
          }
        })
        .catch((error: ResponseError) => {
          setAlert(t(getErrorMessage(error.subStatusCode)));
        });
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  const handleRegister = (userdata: any) => {
    setUserInfo({
      name: userdata.name,
      email: userdata.email,
      password: userdata.password,
    });
    sendRegisterOTP({ email: userdata.email })
      .then((data) => {
        if (data.success) {
          if (data.success) setAlert(t(getSuccessMessage('8001')), 'success');
          navigate('/signup/otp-code');
        }
      })
      .catch((error: ResponseError) => {
        setAlert(t(getErrorMessage(error.subStatusCode)));
      });
  };

  const onToggleLocale = () => {
    localStorage.setItem('locale', locale === 'en' ? 'ja' : 'en');
    setLocale(locale === 'en' ? 'ja' : 'en');
  };

  return (
    <>
      <div className={styles.switch}>
        <Switch value={locale} onToggle={onToggleLocale} />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.logoWrapper}>
          <img src={Logo} alt="Logo" />
          <h1>{t('titles.createAccount')}</h1>
        </div>
        <Input
          label={t('fields.name')}
          placeholder={t('placeholders.name')}
          inputProps={{ ...register('name') }}
          errorMessage={errors.name?.message}
        />
        <Input
          label={t('fields.email')}
          placeholder={t('placeholders.email')}
          inputProps={{
            ...register('email'),
            type: 'email',
          }}
          errorMessage={errors.email?.message}
        />
        <Input
          label={t('fields.password')}
          placeholder={t('placeholders.password')}
          inputProps={{
            ...register('password'),
            type: 'password',
          }}
          errorMessage={errors.password?.message}
        />
        <Input
          label={t('fields.confirmPassword')}
          placeholder={t('placeholders.confirmPassword')}
          inputProps={{
            ...register('confirmPassword'),
            type: 'password',
          }}
          errorMessage={errors.confirmPassword?.message}
        />
        <div
          className={`${styles.termsBox} willeder-inputWrapper `}
          // onClick={() => setOpenModal('termsAndConditions')}
        >
          <CheckBox
            label={t('labels.termsAndConditions')}
            inputProps={{
              ...register('terms'),
              type: 'checkbox',
            }}
            onClick={()=>setOpenModal('termsAndConditions')}
          />
          <p className="willeder-input-error">{errors.terms?.message}</p>
        </div>
        <Button
          onClick={handleSubmit(handleRegister)}
          className="test"
          disabled={isLoading || googleAuthLoading}
          isLoading={isLoading}
        >
          {t('buttons.signup')}
        </Button>
        <div className={styles.ordivider}>
          <span className={styles.line} />
          <p>or</p>
          <span className={styles.line} />
        </div>
        <Button
          className="outlined"
          icon={<GoogleIcon />}
          iconPosition="left"
          disabled={isLoading || googleAuthLoading}
          isLoading={googleAuthLoading}
          onClick={() => googleLogin()}
        >
          {t('buttons.googleSignup')}
        </Button>
        <p className={styles.account}>
          {t('labels.postspaceAccount')}{' '}
          <Link to="/login" className={styles.signin}>
            {t('links.signin')}
          </Link>
        </p>
      </div>
    </>
  );
};

export default Signup;
