import {
  decryptSecretCode,
  encryptSecretCode,
  generateFileKey,
  generateLocalSecretAuth,
  generateRandomSecretCode,
} from 'utils/encryptionUtils';

export const useSecretCode = () => {
  const getSecretCode = (secretKey: string) => {
    const encryptedSecretCode = localStorage.getItem('encryptedSecretCode');
    if (encryptedSecretCode) {
      const secretCode = decryptSecretCode(encryptedSecretCode, secretKey);
      return secretCode;
    }
    return '';
  };

  const getNewSecretAuthCode = (
    userId: string,
    secretKey: string,
    secretSalt: string
  ) => {
    const secretCode = generateRandomSecretCode();
    const encryptedSecretCode = encryptSecretCode(secretCode, secretKey);

    const filekey = generateFileKey(secretCode, secretSalt);
    const secretAuth = generateLocalSecretAuth(userId, filekey);

    return {
      secretCode,
      secretAuth,
      encryptedSecretCode,
    };
  };

  const getSecretAuthCode = (
    secretCode: string,
    userId: string,
    secretKey: string,
    secretSalt: string
  ) => {
    const encryptedSecretCode = encryptSecretCode(secretCode, secretKey);
    const filekey = generateFileKey(secretCode, secretSalt);
    const secretAuth = generateLocalSecretAuth(userId, filekey);
    return { secretAuth, encryptedSecretCode };
  };

  return { getSecretCode, getNewSecretAuthCode, getSecretAuthCode };
};
