import { useSecretCode } from 'hooks/encryption/useSecretCode';
import useAuthStore from 'store/authStore';
import {
  decryptFile,
  decryptFileKeyFromSenderKey,
  decryptSenderKey,
  encryptFile,
  generateFileKey,
} from 'utils/encryptionUtils';

export const useSpaceFile = () => {
  const { secretKey, secretSalt } = useAuthStore();
  const { getSecretCode } = useSecretCode();
  const secretCode = getSecretCode(secretKey);

  const encryptFileData = async (file: File): Promise<File> => {
    const fileKey = generateFileKey(secretCode, secretSalt);
    const encryptedFile = await encryptFile(file, fileKey);
    return encryptedFile;
  };

  const decryptFileData = async (file: File) => {
    const fileKey = generateFileKey(secretCode, secretSalt);
    const decryptedFile = await decryptFile(file, fileKey);
    return decryptedFile;
  };

  const decryptGuestFileData = async (
    file: File,
    senderKey: string,
    transferKey: string
  ) => {
    const fileKey = decryptFileKeyFromSenderKey(senderKey, transferKey);
    const decryptedFile = await decryptFile(file, fileKey);
    return decryptedFile;
  };

  const decryptReceiverFileData = async (
    file: File,
    transferKey: string,
    encryptedSenderKey: string
  ) => {
    const fileKey = generateFileKey(secretCode, secretSalt);
    const senderKey = decryptSenderKey(encryptedSenderKey, fileKey);
    const ownerFileKey = decryptFileKeyFromSenderKey(senderKey, transferKey);
    const decryptedFile = await decryptFile(file, ownerFileKey);
    return decryptedFile;
  };

  return {
    encryptFileData,
    decryptFileData,
    decryptGuestFileData,
    decryptReceiverFileData,
  };
};
