import useSWR from 'swr';
import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import useSWRMutation from 'swr/mutation';
import { getCookies } from 'utils/cookies';

const useSubscriptionPlan = (planId?: string) => {
  const { get, put } = useAPI();
  const isAuthenticated = getCookies('accessToken');

  const {
    data: subscriptionPlan,
    error,
    isLoading,
    mutate,
  } = useSWR<SubscriptionPlanResponse>(
    !!isAuthenticated ? API_ROUTES.getUserPlan : '',
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const sendUpdateRequest = async (url: string, { arg }: { arg: string }) => {
    const response: ChangePlanResponse = await put(
      API_ROUTES.changePlan(arg),
      {}
    );
    mutate();
    return response;
  };

  const {
    trigger: changeUserPlan,
    isMutating: updating,
    error: updateError,
  } = useSWRMutation('CHANGE_PLAN', sendUpdateRequest);

  return {
    subscriptionPlan,
    changeUserPlan,
    isLoading: isLoading || updating,
    error: error || updateError,
  };
};

export default useSubscriptionPlan;
