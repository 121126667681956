import useSWRMutation from 'swr/mutation';
import API_ROUTES from 'services/apiRoutes';
import useAuthStore from 'store/authStore';
import { setCookies } from 'utils/cookies';
import { useSecretCode } from '../encryption/useSecretCode';
import useSecretAuth from './useSecretAuth';
import { useAPI } from '../useAPI';
import useGuestToUser from 'hooks/receiver/useGuestToUser';

const useRegister = () => {
  const { post } = useAPI();
  const { getNewSecretAuthCode } = useSecretCode();
  const { validateRegisterSecretCode } = useSecretAuth();
  const setIsAuthenticated = useAuthStore((state) => state.setIsAuthenticated);
  const setAuthInformation = useAuthStore((state) => state.setAuthInformation);
  const isGuest = localStorage.getItem('isGuest');
  const { onConvertGuestToUser } = useGuestToUser();

  const sendRegisterRequest = async (
    url: string,
    { arg }: { arg: RegisterPayload }
  ) => {
    const response: RegisterResponse = await post(url, arg);
    const { userId, secretKey, secretSalt, accessToken, refreshToken } =
      response.result;

    const authInformation = {
      isNewUser: true,
      userId,
      secretKey,
      secretSalt,
      loginToken: '',
    };

    setAuthInformation(authInformation);
    setCookies('accessToken', accessToken || '');
    setCookies('refreshToken', refreshToken || '', 30);
    setCookies('authInformation', JSON.stringify(authInformation));

    if (isGuest === 'true') onConvertGuestToUser();

    const { secretAuth, encryptedSecretCode } = getNewSecretAuthCode(
      userId,
      secretKey,
      secretSalt
    );
    await validateRegisterSecretCode({
      secretAuth: secretAuth,
      accessToken: accessToken || '',
    }).then((response) => {
      if (response.success) {
        localStorage.setItem('encryptedSecretCode', encryptedSecretCode);
        setIsAuthenticated(true);
      }
    });
    return response;
  };

  const { trigger, isMutating, error } = useSWRMutation(
    API_ROUTES.register,
    sendRegisterRequest
  );

  const sendVerifyOTPRequest = async (
    url: string,
    { arg }: { arg: ForgotPasswordPayload }
  ) => {
    const response: { success: boolean } = await post(url, arg);
    return response;
  };

  const {
    trigger: sendRegisterOTP,
    isMutating: verifying,
    error: verifyError,
  } = useSWRMutation(API_ROUTES.registerSendOTP, sendVerifyOTPRequest);

  return {
    onRegister: trigger,
    sendRegisterOTP,
    isLoading: isMutating || verifying,
    error: verifyError || error,
  };
};

export default useRegister;
