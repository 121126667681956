import React, { useEffect, useState } from 'react';
import styles from './SubscriptionModal.module.scss';
import { Button } from '@willeder/component-library';
import { ReactComponent as Tick } from '../../../assets/icons/tick_green.svg';
import useModalStore from 'store/useModalStore';
import useSubscriptionPlan from 'hooks/profile/useSubscriptionPlan';
import useAlert from 'store/useAlert';
import {
  convertTimestamp,
  getErrorMessage,
  getSuccessMessage,
} from 'utils/helper';
import { useTranslation } from 'react-i18next';

const SubscriptionModal: React.FC = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState<Plan[]>();
  const [planId, setPlanId] = useState('');
  const { subscriptionPlan, changeUserPlan, isLoading } =
    useSubscriptionPlan(planId);
  const { closeModal } = useModalStore();
  const setAlert = useAlert((state) => state.setAlert);
  const { t } = useTranslation();

  useEffect(() => {
    // console.log("subscriptionPlan", subscriptionPlan);

    if (subscriptionPlan?.success) {
      setSubscriptionPlans(subscriptionPlan.result);
    }
  }, [subscriptionPlan]);

  const onPlanChange = (changePlanId: string) => {
    console.log('PLAN ID ===', changePlanId);
    setPlanId(changePlanId);
    changeUserPlan(changePlanId)
      .then((response) => {
        if (response.result?.url) window.location.href = response.result.url;
        closeModal();
        setPlanId('');
        setAlert(t(getSuccessMessage('success')), 'success');
      })
      .catch((error: ResponseError) => {
        setAlert(t(getErrorMessage(error.subStatusCode)));
      });
  };

  const formatStorage = (capacity: number) => {
    return capacity >= 1024 ? `${capacity / 1024} GB` : `${capacity} mb`;
  };

  return (
    <div className={`${styles.spaceSetting} fill-width`}>
      <h1 className={styles.title}>{t('common.chooseYourPlan')}</h1>
      <div className={styles.plans}>
        {subscriptionPlans?.map(
          (plan, index) => (
            (
              <div key={index} className={styles.plans1}>
                <Button className="basic">{plan.title}</Button>
                <span className={styles.amount}>¥{plan.price}</span>
                <span className={styles.userMonth}>/Month</span>
                <div className={styles.subDetails}>
                  <div className={styles.content}>
                    <span className={styles.flexItem}>
                      <Tick />
                      <p className={styles.cardContentPara}>
                        {t('common.fileDeactivatePeriod')}
                      </p>
                    </span>
                    <span className={styles.flexItemFlexEnd}>
                      {plan.planType === 'free'
                        ? t('common.plan.fileDeactivatePeriod.option1', {
                            day: 7,
                          })
                        : t('common.plan.fileDeactivatePeriod.option2')}
                    </span>
                  </div>
                  <div className={styles.content}>
                    <span className={styles.flexItem}>
                      <Tick />
                      <p className={styles.cardContentPara}>
                        {t('common.fileSavePeriod')}
                      </p>
                    </span>
                    <span className={styles.flexItemFlexEnd}>
                      {t('common.plan.fileSavePeriod.option', {
                        day: plan.fileRetentionPeriod,
                      })}
                    </span>
                  </div>
                  <div className={styles.content}>
                    <span className={styles.flexItem}>
                      <Tick />
                      <p className={styles.cardContentPara}>
                        {t('common.totalUploadingSize')}
                      </p>
                    </span>
                    <span className={styles.flexItemFlexEnd}>
                      {formatStorage(plan.storageCapacity)}
                    </span>
                  </div>
                </div>
                <div className={styles.planButton}>
                  <Button
                    className="choosePlan"
                    onClick={() => onPlanChange(plan.planId)}
                    isLoading={isLoading && planId === plan.planId}
                    disabled={plan.default}
                  >
                    {plan.default
                      ? t('common.currentPlan')
                      : t('common.choosePlan')}
                  </Button>
                </div>
              </div>
            )
          )
        )}
      </div>
    </div>
  );
};

export default SubscriptionModal;
