import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import OtpInput from 'components/atoms/OtpInput';
import useForgotPassword from 'hooks/auth/useForgotPassword';
import useRegister from 'hooks/auth/useRegister';
import useAuthStore from 'store/authStore';
import useAlert from 'store/useAlert';
import useModalStore from 'store/useModalStore';
import { getErrorMessage, getSuccessMessage } from 'utils/helper';
import Logo from '../../assets/icons/logo.svg';
import styles from './OTPVerification.module.scss';

interface OTPCodeProps {
  screen: string;
}

const OTPVerification: React.FC<OTPCodeProps> = ({ screen }) => {
  const navigate = useNavigate();
  const email = useAuthStore((state) => state.email);
  const setOTPId = useAuthStore((state) => state.setOTPId);
  const { locale, password, name, setNewUser } = useAuthStore((state) => state);
  const setAlert = useAlert((state) => state.setAlert);
  const { t } = useTranslation();
  const [otp, setOtp] = useState('');
  const { setOpenModal, setModalProps } = useModalStore();

  const onChange = (value: string) => setOtp(value);
  const { verifyPasswordOTP, onForgotPassword, isLoading } =
    useForgotPassword();
  const {
    onRegister,
    sendRegisterOTP,
    isLoading: registerLoading,
  } = useRegister();

  const onVerifyOTP = () => {
    if (screen === 'ForgotPassword') {
      verifyPasswordOTP({ otp })
        .then((response) => {
          if (response.success) {
            setOTPId(response.otpId);
            navigate('/reset-password');
          }
        })
        .catch((error: ResponseError) =>
          setAlert(t(getErrorMessage(error.subStatusCode)))
        );
    } else {
      const prepareData: RegisterPayload = {
        otp: otp,
        name: name,
        email: email,
        password: password,
        locale: locale,
      };
      onRegister(prepareData)
        .then((data) => {
          if (data.success) {
            setNewUser(true);
            setOpenModal('successModal');
            setModalProps({
              message: t(getSuccessMessage('8003')),
              onContinue: () => navigate('/'),
            });
          }
        })
        .catch((error: ResponseError) => {
          setAlert(t(getErrorMessage(error.subStatusCode)));
        });
    }
  };

  const onResendOTP = () => {
    const onVerify =
      screen === 'ForgotPassword' ? onForgotPassword : sendRegisterOTP;
    onVerify({ email })
      .then((response) => {
        if (response.success) setAlert(t(getSuccessMessage('8001')), 'success');
      })
      .catch((error: ResponseError) =>
        setAlert(t(getErrorMessage(error.subStatusCode)))
      );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <img src={Logo} alt="Logo" />
        <h1>{t('titles.enterOTP')}</h1>
        <p>{t('labels.otpSentToEmail', { email })}</p>
      </div>
      <div className={styles.otpWrapper}>
        <OtpInput value={otp} valueLength={6} onChange={onChange} />
        <div className={styles.textWrapper}>
          <p>
            {t('labels.didNotGetOTP')}{' '}
            <span onClick={onResendOTP}>{t('links.resend')}</span>
          </p>
        </div>
        <Button
          disabled={isLoading || registerLoading || otp?.trim().length !== 6}
          onClick={onVerifyOTP}
          isLoading={isLoading || registerLoading}
          className={otp?.trim().length === 6 ? 'buttonBlue' : 'buttonGrey'}
        >
          {t('buttons.verify')}
        </Button>
      </div>
    </div>
  );
};

export default OTPVerification;
