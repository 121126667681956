import EN_BUTTONS from './buttons.json';
import EN_COMMON from './common.json';
import EN_ERRORS from './errors.json';
import EN_FIELDS from './fields.json';
import EN_LABELS from './labels.json';
import EN_LINKS from './links.json';
import EN_NAVIGATIONS from './navOptions.json';
import EN_PLACEHOLDERS from './placeholders.json';
import EN_SUCCESS from './success.json';
import EN_TITLES from './titles.json';

export const translations = {
  buttons: EN_BUTTONS,
  common: EN_COMMON,
  errors: EN_ERRORS,
  fields: EN_FIELDS,
  labels: EN_LABELS,
  links: EN_LINKS,
  navOptions: EN_NAVIGATIONS,
  placeholders: EN_PLACEHOLDERS,
  success: EN_SUCCESS,
  titles: EN_TITLES,
};
