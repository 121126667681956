import React, { useState } from 'react';
import { Button } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/atoms/CheckBox';
import useModalStore from 'store/useModalStore';
import styles from './TransferReadmeModal.module.scss';

const TransferReadmeModal = () => {
  const { t } = useTranslation();
  const [showReadme, setShowReadme] = useState(false);
  const { closeModal } = useModalStore();
  const steps: any = t('common.spaceReadme.steps', { returnObjects: true });

  return (
    <div className={`${styles.transferModal} gTransferModalStyle`}>
      <div className={styles.titleWrapper}>
        <h1>{t('common.spaceReadme.title')}</h1>
      </div>
      <div className={styles.description}>
        {steps?.map((step: any, index: number) => (
          <React.Fragment key={index}>
            <p>{step.title}</p>
            <span className={styles.content}>{step.description}</span>
          </React.Fragment>
        ))}
      </div>
      <div className={styles.checkboxWrapper}>
        <CheckBox
          label={t('common.spaceReadme.doNotShowReadme')}
          checked={showReadme}
          onChange={(checked) => {
            setShowReadme(checked);
            localStorage.setItem(
              'isDisplaySpaceReadme',
              checked ? 'false' : 'true'
            );
          }}
        />
      </div>
      <div className={styles.btnWrapper}>
        <Button className="close" onClick={closeModal}>
          {t('buttons.close')}
        </Button>
      </div>
    </div>
  );
};

export default TransferReadmeModal;
