export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const passwordRegex =
  /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/;

export const RE_DIGIT = new RegExp(/^\d+$/);

export const colorOptions = [
  { id: 'orange', color: 'orange' },
  { id: 'navy', color: 'navy' },
  { id: 'brown', color: 'brown' },
  { id: 'lightgreen', color: 'lightgreen' },
  { id: 'darkgreen', color: 'darkgreen' },
  { id: 'blue', color: 'blue' },
  { id: 'red', color: 'red' },
  { id: 'black', color: 'black' },
];

export const timeOptions = [
  {
    label: '3H',
    value: '3h',
  },
  {
    label: '12H',
    value: '12h',
  },
  {
    label: '24H',
    value: '24h',
  },
  {
    label: '3日(day)',
    value: '3d',
  },
  {
    label: '7日(day)',
    value: '7d',
  },
  {
    label: '14日(day)',
    value: '14d',
  },
];


export const localeOptions = [
  { label: '日本語', value: 'ja' },
  { label: 'English', value: 'en' },
];
