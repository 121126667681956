import React, { useState } from 'react';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/Group.svg';
import { ReactComponent as ActiveIcon } from '../../../assets/icons/active.svg';
import { ReactComponent as WaitingIcon } from '../../../assets/icons/waiting.svg';
import { ReactComponent as RequestIcon } from '../../../assets/icons/request.svg';
import { ReactComponent as DeactivateIcon } from '../../../assets/icons/deactivate.svg';
import { ReactComponent as ExclamationIcon } from '../../../assets/icons/exclamation-white.svg';
import styles from './UserDetails.module.scss';
import { useTranslation } from 'react-i18next';
import tooltip from 'components/atoms/ToolTip/ToolTip';

const ToolTipCopyIcon = tooltip(CopyIcon, {
  className: {
    container: 'react-tooltip',
    content: 'react-tooltip__content',
  },
  styles: {
    container: {
      backgroundColor: '#333',
      color: '#fff',
      padding: '5px',
      borderRadius: '3px',
      fontSize: '12px',
    },
    content: {
      fontSize: '12px',
    },
  },
});
const ToolTipMenuIconIcon = tooltip(MenuIcon, {
  className: {
    container: 'react-tooltip',
    content: 'react-tooltip__content',
  },
  styles: {
    container: {
      backgroundColor: '#333',
      color: '#fff',
      padding: '5px',
      borderRadius: '3px',
      fontSize: '12px',
    },
    content: {
      fontSize: '12px',
    },
  },
});

interface UserStatusMoleculeProps {
  userName: string;
  status?: string;
  isGuest?: boolean;
  onEditReceiver: () => void;
  onOpenGeneratedLink: () => void;
  onRegenerateLink: () => void;
  onDeactivateReceiver: () => void;
  onRemoveReceiver: () => void;
  onCopyGeneratedLink: () => void;
  readStatus: 'read' | 'unread';
}

const UserDetails: React.FC<UserStatusMoleculeProps> = React.memo(
  ({
    userName,
    status,
    isGuest = false,
    onEditReceiver,
    onOpenGeneratedLink,
    onRegenerateLink,
    onDeactivateReceiver,
    onRemoveReceiver,
    onCopyGeneratedLink,
    readStatus,
  }) => {
    const { t } = useTranslation();

    const statusInfo: {
      [key: string]: { icon: React.ReactNode; color: string };
    } = {
      active: { icon: <ActiveIcon />, color: '#00800D' },
      // waiting: { icon: <WaitingIcon />, color: '#FFC700' },
      waiting: { icon: <WaitingIcon />, color: '#FFA500' },
      unread: { icon: <WaitingIcon />, color: '#FFC700' },
      request: { icon: <RequestIcon />, color: '#545454' },
      requested: { icon: <RequestIcon />, color: '#545454' },
      deactivate: { icon: <DeactivateIcon />, color: '#FF2626' },
      expired: { icon: <DeactivateIcon />, color: '#FF2626' },
      deactivated: { icon: <DeactivateIcon />, color: '#FF2626' },
    };

    console.log(status);

    const statusKey = status ? status.toLowerCase() : '';
    const statusInfoItem =
      readStatus === 'unread' && status === 'active'
        ? statusInfo['unread']
        : statusInfo[statusKey] || {
            icon: null,
            color: '#000000',
          };

    const [isMenuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
      setMenuVisible(!isMenuVisible);
    };

    const statusLabel =
      readStatus === 'unread' && status === 'active'
        ? `labels.unread`
        : `labels.${status}`;

    const tooltipLabel = (status: string | undefined)=>{
      switch (status) {
        case 'active':
          return t('labels.copiedLinkActive');
        case 'waiting':
          return t('labels.copiedLinkWaiting');
        default:
          return t('labels.copiedLinkWaiting')
      }
    }

    return (
      <nav
        className={styles.molecule}
        onBlur={() => setMenuVisible(false)}
        tabIndex={0}
      >
        <div className={styles.userDetails}>
          <div className={styles.userName}>
            {userName}
            {isGuest ? t('labels.guest') : ''}
          </div>
          {status && <div
            className={styles.statusContainer}
            style={{ color: statusInfoItem.color }}
          >
            {statusInfoItem.icon}
            <span className={styles.status}>{t(statusLabel)}</span>
          </div>}
        </div>
        <div className={styles.right}>
          <span className={styles?.[status || 'default']}>
            <ToolTipCopyIcon
              className={styles.copyIcon}
              onClick={onCopyGeneratedLink}
              title={tooltipLabel(status)}
            />
          </span>
          <span>
            <ToolTipMenuIconIcon
              title={t("labels.menu")}
              className={styles.menuIcon}
              onClick={toggleMenu}
            />
          </span>
          {isMenuVisible && (
            <nav className={styles.menuItems}>
              <div className={styles.item} onClick={onEditReceiver}>
                {t('links.edit')}
              </div>
              <div className={styles.item} onClick={onOpenGeneratedLink}>
                {t('links.generatedLink')}
              </div>
              <div className={styles.item} onClick={onRegenerateLink}>
                {t('links.regenerateLink')}
              </div>
              <div className={styles.item} onClick={onDeactivateReceiver}>
                {status === 'deactivated'
                  ? t('links.activateReceiver')
                  : t('links.deactivateReceiver')}
              </div>
              <div className={styles.item} onClick={onRemoveReceiver}>
                {t('links.removeReceiver')}
              </div>
            </nav>
          )}
        </div>
      </nav>
    );
  }
);

export default UserDetails;
