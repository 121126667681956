import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import useSWRMutation from 'swr/mutation';

const useForgotPassword = () => {
  const { post, put } = useAPI();

  const sendForgotPasswordRequest = async (
    url: string,
    { arg }: { arg: ForgotPasswordPayload }
  ) => {
    const response: { success: boolean } = await post(url, arg);
    return response;
  };

  const {
    trigger: onForgotPassword,
    isMutating: isLoading,
    error,
  } = useSWRMutation(API_ROUTES.forgotPassword, sendForgotPasswordRequest);

  const sendVerifyOTPRequest = async (
    url: string,
    { arg }: { arg: VerifyOTPPayload }
  ) => {
    const response: VerifyOTPResponse = await put(url, arg);
    return response;
  };

  const {
    trigger: verifyPasswordOTP,
    isMutating: verifying,
    error: verifyError,
  } = useSWRMutation(API_ROUTES.verifyPasswordOTP, sendVerifyOTPRequest);

  return {
    onForgotPassword,
    verifyPasswordOTP,
    isLoading: isLoading || verifying,
    error: error || verifyError,
  };
};

export default useForgotPassword;
