import React from 'react';
import { Button } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import usePaymentCard from 'hooks/payment/usePaymentCard';
import useModalStore from 'store/useModalStore';
import useAlert from 'store/useAlert';
import useAuthStore from 'store/authStore';
import { getErrorMessage } from 'utils/helper';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import MastercardIcon from '../../../assets/icons/card_icons/2.png';
import VisaIcon from '../../../assets/icons/card_icons/1.png';
import styles from './PaymentCardsModal.module.scss';

interface CardBrandIcons {
  [key: string]: string;
}

const PaymentCardsModal = () => {
  const { t } = useTranslation();
  const { setPaymentMethodId } = useAuthStore();
  const { setOpenModal, closeModal } = useModalStore();
  const setAlert = useAlert((state) => state.setAlert);

  const { paymentCards, deleteCard, setDefaultCard } = usePaymentCard();

  const cardBrandIcons: CardBrandIcons = {
    mastercard: MastercardIcon,
    visa: VisaIcon,
  };

  const onAddCard = () => {
    setOpenModal('addPaymentCard');
  };

  const onDeleteCard = (paymentMethodId: string) => {
    setPaymentMethodId(paymentMethodId);
    deleteCard(paymentMethodId)
      .then((response) => {
        setAlert('success', 'success');
      })
      .catch((error) => {
        setAlert(t(getErrorMessage(error?.subStatusCode)));
      });
  };

  const onMakeDefaultCard = (paymentMethodId: string) => {
    setPaymentMethodId(paymentMethodId);
    setDefaultCard(paymentMethodId)
      .then((response) => {
        setAlert('success', 'success');
      })
      .catch((error) => {
        setAlert(t(getErrorMessage(error?.subStatusCode)));
      });
  };

  return (
    <div className={styles.editModal}>
      <h1 className={styles.title}>{t('navOptions.myCards')}</h1>
      <div className={styles.editWrapper}>
        {paymentCards?.result.paymentMethodFields.map((card) => (
          <div className={styles.wrapper} key={card.paymentMethodId}>
            <div className={styles.cardWrapper}>
              <div className={styles.defaultWrapper}>
                <h5>
                  xxxx-xxxx-xxxx-{card.last4}
                  {/* <i>{card.brand}</i> */}
                </h5>
                {paymentCards.result.defaultPaymentMethod ===
                  card.paymentMethodId && (
                  <span className={styles.default}>{t('labels.default')}</span>
                )}
              </div>
              <div className={styles.setDefaultWrapper}>
                {paymentCards.result.defaultPaymentMethod !==
                  card.paymentMethodId && (
                  <div
                    className={styles.setDefault}
                    onClick={() => onMakeDefaultCard(card.paymentMethodId)}
                  >
                    {t('links.setDefault')}
                  </div>
                )}
              </div>
            </div>
            <div
              className={styles.cardIcon}
              onClick={() => onDeleteCard(card.paymentMethodId)}
            >
              {cardBrandIcons[card.brand] && (
                <img src={cardBrandIcons[card.brand]} alt={card.brand} />
              )}
              <DeleteIcon />
            </div>
          </div>
        ))}
      </div>
      <div className={`${styles.buttonWrapper} ${styles.columnAlignCenter}`}>
        <Button className="test" onClick={onAddCard}>
          {t('buttons.addCard')}
        </Button>
        <Button className="close" onClick={closeModal}>
          {t('buttons.close')}
        </Button>
      </div>
    </div>
  );
};

export default PaymentCardsModal;
