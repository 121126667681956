import { useSecretCode } from 'hooks/encryption/useSecretCode';
import useAuthStore from 'store/authStore';
import {
  encryptSenderKey,
  generateFileKey,
  generateSenderKey,
  generateSharedKey,
} from 'utils/encryptionUtils';

export const useCryptoLink = () => {
  const { secretKey, secretSalt } = useAuthStore();
  const { getSecretCode } = useSecretCode();

  const getSharedKey = (transferKey: string) => {
    const secretCode = getSecretCode(secretKey);
    const fileKey = generateFileKey(secretCode, secretSalt);
    const senderKey = generateSenderKey(fileKey, transferKey);
    return senderKey;
  };

  const generateLink = (receiverId: string, transferKey: string) => {
    const secretCode = getSecretCode(secretKey);
    const fileKey = generateFileKey(secretCode, secretSalt);
    const senderKey = generateSenderKey(fileKey, transferKey);
    const sharedKey = generateSharedKey(senderKey);

    const link = `${process.env.REACT_APP_FRONT_URL}/receiver-space/${receiverId}/${sharedKey}`;

    return link;
  };

  const generateSharedSpaceLink = (spaceId: string) => {
    const secretCode = getSecretCode(secretKey);
    const fileKey = generateFileKey(secretCode, secretSalt);
    const senderKey = generateSenderKey(fileKey, spaceId);
    const sharedSpaceKey = generateSharedKey(senderKey);
    const spaceIdBase64 = generateSharedKey(spaceId);

    const link = `${process.env.REACT_APP_FRONT_URL}/shared-space/${spaceIdBase64}/${sharedSpaceKey}`;

    return link;
  };

  const generateSharedFilesLink = (bulkId: string) => {
    const secretCode = getSecretCode(secretKey);
    const fileKey = generateFileKey(secretCode, secretSalt);
    const senderKey = generateSenderKey(fileKey, bulkId);
    const sharedBulkId = generateSharedKey(senderKey);
    const sharedFilesLinkId = generateSharedKey(bulkId);
    const link = `${process.env.REACT_APP_FRONT_URL}/shared-files/${sharedFilesLinkId}/${sharedBulkId}`;
    return link;
  };

  const getEncryptedSenderKey = (senderKey: string) => {
    const secretCode = getSecretCode(secretKey);
    const fileKey = generateFileKey(secretCode, secretSalt);
    const encryptedSenderKey = encryptSenderKey(senderKey, fileKey);
    return encryptedSenderKey;
  };

  const getEncryptedSenderKeyForGuestToUser = (senderKey: string) => {
    const secretCode = getSecretCode(secretKey);
    const fileKey = generateFileKey(secretCode, secretSalt);
    const encryptedSenderKey = encryptSenderKey(senderKey, fileKey);
    return encryptedSenderKey;
  };

  return {
    getSharedKey,
    generateLink,
    generateSharedSpaceLink,
    getEncryptedSenderKey,
    generateSharedFilesLink,
    getEncryptedSenderKeyForGuestToUser,
  };
};
