import React, { useEffect, useState } from 'react';
import { Router } from 'routes';
// import { Socket } from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import ToastNotification from 'components/molecules/ToastNotification';
import GlobalModal from 'components/organism/GlobalModal';
import useNotification from 'hooks/notification/useNotification';
import useProfile from 'hooks/profile/useProfile';
import useAuthStore from 'store/authStore';
import useNotificationStore from 'store/notificationStore';
import useModalStore from 'store/useModalStore';
// import { initializeSocketConnection } from 'utils/socket';
import { getCookies } from 'utils/cookies';
import './i18n/config';
import useNotificationSocket from 'hooks/useNotificationSocket';

const App = () => {
  const locale = useAuthStore((state) => state.locale);
  const { i18n } = useTranslation();

  // const [socket, setSocket] = useState<Socket>();

  const { isAuthenticated } = useAuthStore((state) => state);
  const accessToken = getCookies('accessToken');
  // const { setOpenModal } = useModalStore();
  const {
    setNotificationStatus,
    socket,
    // setNotificationData,
    // setReloadReceiverStatus,
  } = useNotificationStore();
  // const { getNotificationData } = useNotification();
  const { getProfileData, userData } = useProfile();
  const { connectToNotificationSocket } = useNotificationSocket();

  useEffect(() => {
    const localLocale = localStorage.getItem('locale');
    console.log("=>>> locales", localLocale)
    i18n.changeLanguage(localLocale || locale);
  }, [locale]);

  useEffect(() => {
    if (isAuthenticated && accessToken) {
      getProfileData()
        .then((response) => {
          if (response?.success && response.result?.isNotification) {
            setNotificationStatus(response.result?.isNotification);
            // setNotificationData(response);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [accessToken]);

  useEffect(() => {
    if (!socket?.active && !!accessToken && userData?.success) {
      connectToNotificationSocket();
      // const socket = initializeSocketConnection();
      // if (socket) {
      //   try {
      //     setSocket(socket);
      //     if (!socket.active) socket?.connect();
      //     console.log('******STARTING SOCKET********');
      //     socket
      //       .off('receiverAccessPermission')
      //       .on(
      //         'receiverAccessPermission',
      //         (data: NotificationSocketResponse) => {
      //           console.log('=== receiverId ===', data);
      //           if (data.message === 'notification')
      //             setNotificationStatus(!!data.receiver);
      //           else if (data.message === 'receiverStatus') {
      //             setReloadReceiverStatus(true);
      //           }
      //         }
      //       );
      //     socket.on('disconnect', (reason) => {
      //       socket.disconnect();
      //       if (userData) setOpenModal('duplicateTabModal');
      //       console.log('Disconnected from the server ==', reason);
      //     });
      //   } catch (e) {
      //     if (socket.active) socket.disconnect();
      //     console.log(e);
      //   }
      // }
    }
  }, [isAuthenticated, accessToken, userData, socket]);

  useEffect(() => {
    return () => {
      if (socket && socket?.active) {
        console.log('CLOSED SOCKET!!');
        socket?.disconnect();
      }
    };
  }, [socket]);

  return (
    <>
      <ToastNotification />
      <Router />
      <GlobalModal />
    </>
  );
};

export default App;
