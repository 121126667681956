import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface TooltipsProps {
  children: React.ReactNode;
}

const Tooltips: React.FC<TooltipsProps> = ({ children }) => {
  const elementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    let element = document.getElementById('toolTipContentWrapper') as HTMLElement;

    if (!element) {
      element = document.createElement('div');
      element.id = 'toolTipContentWrapper';
      document.body.appendChild(element);
    }

    elementRef.current = element;

    return () => {
      if (elementRef.current && document.body.contains(elementRef.current)) {
        document.body.removeChild(elementRef.current);
      }
    };
  }, []);

  if (!elementRef.current) {
    return null;
  }

  return createPortal(children, elementRef.current);
};

export default Tooltips;
